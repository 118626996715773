import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

interface TosProps {
  open: boolean;
  onClose: () => void;
}

const TosDialog: React.FC<TosProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Terms of Service</DialogTitle>
      <DialogContent>
        <DialogContentText>
          By using this site, you agree to the terms of service.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TosDialog;
