import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Tooltip,
  IconButton,
  InputAdornment,
  Switch,
  FormControlLabel,
  Link,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info"; // Import the Info icon
import { APIRoutePaths, buildAPIPath } from "../../utils/api";
import {
  countries,
  states,
  colleges,
  undergrad_degrees,
} from "../../utils/static_data";
import { useParams } from "react-router-dom";
import RoutePaths from "../../routes/router-config";
import logo from "../../assets/images/logo.svg";
import TosDialog from "../../components/onboarding/tos"; // Adjust the path as needed
import PrivacyPolicydialog from "../../components/onboarding/privacypolicy"; // Adjust the path as needed
import { CreateUserData } from "../../utils/types";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const fetchEmail = async (token: string | undefined) => {
  if (!token) {
    return null;
  }
  try {
    const response = await fetch(
      buildAPIPath(APIRoutePaths.GetInvitedUserEmail),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data.email;
    } else {
      console.log("Not ok");
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const InvitedUserPage: React.FC = () => {
  const { token, replaceEmail } = useParams<{
    token: string;
    replaceEmail: string;
  }>();
  const shouldReplaceEmail = replaceEmail === "true";
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");
  const [formData, setFormData] = useState<CreateUserData>({
    email: "",
    email_visible: false,
    first_name: "",
    last_name: "",
    password: "",
    sex: "",
    birthdate: "",
    city: "",
    state: "",
    country: "",
    college: "",
    college_degree_type: "",
    college_grad_year: new Date().getFullYear(),
    college_major: "",
    profile_link: "",
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [confirm_password, setConfirmPassword] = useState<string>("");
  const [tosOpen, setTosOpen] = useState(false);
  const [ppOpen, setPpOpen] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [viewInfo, setViewInfo] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const fetchAndSetEmail = async () => {
      const fetchedEmail = await fetchEmail(token);
      if (fetchedEmail) {
        setFormData((prevData) => ({ ...prevData, email: fetchedEmail }));
        setOldEmail(fetchedEmail);
      } else {
        // Redirect or handle case where email is not fetched
        console.log(fetchedEmail);
        window.location.href = RoutePaths.Index;
      }
    };
    fetchAndSetEmail();
  }, [token]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log("Submitting form...");
    setErrorMessage(null);
    event.preventDefault();

    // Function to replace empty strings with null
    const replaceEmptyStringsWithNull = (data: CreateUserData) => {
      return Object.keys(data).reduce((acc, key) => {
        const value = data[key as keyof CreateUserData];
        (acc as any)[key as keyof CreateUserData] = value === "" ? null : value;
        return acc;
      }, {} as CreateUserData);
    };

    // Preprocess formData
    const processedData = replaceEmptyStringsWithNull(formData);

    const passwordRequirements =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
    if (formData.password !== confirm_password) {
      setErrorMessage("Passwords do not match.");
      setButtonEnabled(true);
      return;
    }
    if (!passwordRequirements.test(formData.password)) {
      setErrorMessage(
        "Password must be at least 10 characters long, contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol.",
      );
      setButtonEnabled(true);
      return;
    }
    if (!formData.profile_link.startsWith("http")) {
      setErrorMessage("Profile link must start with 'http://' or 'https://'.");
      setButtonEnabled(true);
      return;
    }
    try {
      const response = await fetch(buildAPIPath(APIRoutePaths.CreateAccount), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...processedData }),
      });
      if (response.ok) {
        window.location.href = RoutePaths.Index;
      } else {
        const errorData = await response.json();
        const detail = errorData.detail;
        setErrorMessage(
          `Failed to create account. Please check your details and try again. \n${detail}`,
        );
        setButtonEnabled(true);
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setButtonEnabled(true);
    }
  };

  const handleUpdateEmail = async () => {
    setErrorMessage("");
    if (newEmail === "") {
      setErrorMessage("New email must be valid new email");
      setButtonEnabled(true);
      return;
    }
    if (newEmail !== confirmNewEmail) {
      setErrorMessage("Email and confirm email must match");
      setButtonEnabled(true);
      return;
    }
    if (newEmail === oldEmail) {
      setErrorMessage(
        "You must use a new email. If you would like to use your current email, please use the create account link found in your invite.",
      );
      setButtonEnabled(true);
      return;
    }
    const response = await fetch(
      buildAPIPath(APIRoutePaths.UpdateInviteEmail),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ new_email: newEmail }),
      },
    );

    if (response.ok) {
      window.location.href = RoutePaths.Index;
    } else {
      const errorData = await response.json();
      const detail = errorData.detail;
      setErrorMessage(
        `Failed to update email with error: ${detail} Please try again.`,
      );
      setButtonEnabled(true);
    }
  };

  if (shouldReplaceEmail) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "auto",
          width: "100%",
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        {/* Logo */}
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            mb: 4, // Margin bottom to separate the logo from the form
          }}
        >
          <img
            src={logo}
            alt="Sefer Echad"
            style={{ width: "300px", height: "auto" }}
          />
        </Box>
        {/* Change email form */}
        <Box
          component="form"
          onSubmit={(event) => {
            event.preventDefault();
            setButtonEnabled(false);
            handleUpdateEmail();
          }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: "400px", // Limit the form width
            padding: "2rem",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: add some shadow to the form
            mb: 8, // Margin bottom to separate the form from the footer
          }}
        >
          <Typography variant="body2" sx={{ mb: 2 }}>
            You were initially invited with the email {oldEmail}. Please enter
            the email you would like to change to below.
          </Typography>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            name="email"
            value={newEmail}
            onChange={(event) => setNewEmail(event.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Confirm Email"
            variant="outlined"
            type="email"
            name="confemail"
            value={confirmNewEmail}
            onChange={(event) => setConfirmNewEmail(event.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            disabled={!buttonEnabled}
          >
            Update Email
          </Button>
          {errorMessage && (
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
  if (viewInfo) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100vh",
          width: "100%",
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <Box width="30%" textAlign="center">
          <Typography variant="h2" sx={{ mt: 4 }}>
            Sefer Echad
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 4, mb: 4, fontFamily: "Playfair Display, serif" }}
          >
            Sefer Echad is a network for people dedicated to engaging with and
            supporting the Jewish community driven by a commitment to the Jewish
            community and a love for Israel.
            <br />
            <br />
            Sefer Echad is maintained by a board of facilitators.
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 4, mb: 4, fontFamily: "Playfair Display, serif" }}
          >
            For any inquiries, please contact us at{" "}
            <Link href="mailto:board@seferechad.com">board@seferechad.com</Link>
          </Typography>
        </Box>
        <Button
          sx={{ mt: 2, mb: 4 }}
          variant="contained"
          color="primary"
          onClick={() => setViewInfo(false)}
        >
          Continue
        </Button>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            mt: 4,
          }}
        >
          <img
            src={logo}
            alt="Sefer Echad"
            style={{ width: "300px", height: "auto" }}
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "auto",
        width: "100%",
        backgroundColor: "white",
        textAlign: "center",
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          mb: 4, // Margin bottom to separate the logo from the form
        }}
      >
        <img
          src={logo}
          alt="Sefer Echad"
          style={{ width: "300px", height: "auto" }}
        />
      </Box>
      {/* Login form */}
      <Box
        component="form"
        onSubmit={(event) => {
          setButtonEnabled(false);
          handleSubmit(event);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "400px", // Limit the form width
          padding: "2rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: add some shadow to the form
          mb: 8, // Margin bottom to separate the form from the footer
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          value={formData.email}
          disabled
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="First Name"
          variant="outlined"
          type="text"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          type="text"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          required
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Password must be at least 10 characters long, contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol.">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Confirm Password"
          variant="outlined"
          type={showConfirmPassword ? "text" : "password"}
          name="confirm_password"
          value={confirm_password}
          onChange={(event) => setConfirmPassword(event.target.value)}
          required
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          options={["M", "F", "O"]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sex"
              variant="outlined"
              name="sex"
              required
              sx={{ mb: 2 }}
            />
          )}
          value={formData.sex}
          onChange={(event, value) =>
            setFormData({ ...formData, sex: value || "" })
          }
          fullWidth
        />
        <TextField
          label="Birthdate"
          variant="outlined"
          type="date"
          name="birthdate"
          value={formData.birthdate}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 2 }}
        />
        <TextField
          label="City"
          variant="outlined"
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Autocomplete
          options={states}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              variant="outlined"
              name="state"
              required={formData.country === "USA"}
              sx={{ mb: 2 }}
            />
          )}
          value={formData.state}
          onChange={(event, value) =>
            setFormData({ ...formData, state: value || "" })
          }
          fullWidth
        />
        <Autocomplete
          options={countries}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              variant="outlined"
              name="country"
              required
              sx={{ mb: 2 }}
            />
          )}
          value={formData.country}
          onChange={(event, value) =>
            setFormData({ ...formData, country: value || "" })
          }
          fullWidth
        />
        <Autocomplete
          options={[...colleges, "Other"]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="College"
              variant="outlined"
              name="college"
              required
              sx={{ mb: 2 }}
            />
          )}
          value={formData.college}
          onChange={(event, value) =>
            value !== "Other"
              ? setFormData({ ...formData, college: value || "" })
              : null
          }
          fullWidth
        />
        {formData.college === "Other" && (
          <TextField
            label="Other College"
            variant="outlined"
            type="text"
            name="other_college"
            value={formData.college}
            onChange={handleChange}
            required
            fullWidth
            sx={{ mb: 2 }}
          />
        )}
        <Autocomplete
          options={undergrad_degrees}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Undergraduate Degree"
              variant="outlined"
              name="undergrad_degree"
              required
              sx={{ mb: 2 }}
            />
          )}
          value={
            undergrad_degrees.find(
              (option) => option.value === formData.college_degree_type,
            ) || null
          }
          onChange={(event, value) =>
            setFormData({
              ...formData,
              college_degree_type: value?.value || "",
            })
          }
          fullWidth
        />
        <TextField
          label="College Graduation Year"
          variant="outlined"
          type="number"
          name="college_grad_year"
          value={formData.college_grad_year}
          onChange={handleChange}
          required
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="College Major"
          variant="outlined"
          type="text"
          name="college_major"
          value={formData.college_major}
          onChange={handleChange}
          required
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="LinkedIn Profile"
          variant="outlined"
          type="text"
          name="profile_link"
          value={formData.profile_link}
          onChange={handleChange}
          required
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="We use your LinkedIn to help others connect with you. If you don't have LinkedIn, feel free to include a profile from another site.">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={
            <Box display="flex" alignItems="center" sx={{ paddingLeft: 2 }}>
              <Switch
                checked={formData.email_visible}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    email_visible: event.target.checked,
                  })
                }
                name="email_visible"
                color="primary"
                sx={{ transform: "scale(1.5)", marginRight: 1 }}
              />
              <Tooltip title="If you'd like, we can hide your email from other users. They can then request to contact you through our site, giving you the power to choose who you communicate with.">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          }
          label="Email Visibility"
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        />
        <Typography variant="body2" sx={{ mb: 2 }}>
          By creating an account, you agree to our{" "}
          <Link onClick={() => setTosOpen(true)}>Terms of Service</Link> and{" "}
          <Link onClick={() => setPpOpen(true)}>Privacy Policy</Link>.
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          disabled={!buttonEnabled}
        >
          Create Account
        </Button>
        {errorMessage && (
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
      <TosDialog
        open={tosOpen}
        onClose={() => {
          setTosOpen(false);
        }}
      />
      <PrivacyPolicydialog
        open={ppOpen}
        onClose={() => {
          setPpOpen(false);
        }}
      />
    </Box>
  );
};

export default InvitedUserPage;
