import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { EmploymentSearchResult, UserSearchResult } from "../../utils/types";
import { buildAPIPath, APIRoutePaths, report_action } from "../../utils/api";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface UserSearchCardProps {
  user: UserSearchResult;
  width: string;
}

const UserSearchCard: React.FC<UserSearchCardProps> = ({ user, width }) => {
  const [open, setOpen] = useState(false);
  const [writeMessage, setWriteMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [profileButtonEnabled, setProfileButtonEnabled] = useState(true);
  const [sendButtonEnabled, setSendButtonEnabled] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    report_action("user_card_clicked", user.id, undefined);
  };

  const handleClose = () => {
    setOpen(false);
    setWriteMessage(false);
    setMessage("");
    setErrorMessage("");
  };

  const handleSendMessage = async () => {
    // Implement send message backend call here
    try {
      const response = await fetch(buildAPIPath(APIRoutePaths.SendMessage), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          to_user_id: user.id,
          message: message,
        }),
      });

      if (response.ok) {
        // Handle success
        setMessage("");
        setOpen(false);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error sending the message. Please try again.",
      );
    }
  };

  const formatLocation = () => {
    const locationParts = [user.city, user.state, user.country].filter(Boolean);
    return locationParts.join(", ");
  };

  const [snackOpen, snackSetOpen] = useState(false);
  const handleCopy = () => {
    snackSetOpen(true);
    report_action("direct_message", user.id, undefined);
  };

  return (
    <>
      <Card
        onClick={handleClickOpen}
        sx={{ marginBottom: "1rem", cursor: "pointer", width: width }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box width="50%">
            <Typography variant="h6">
              {user.first_name} {user.last_name}
            </Typography>
            <Typography variant="body2">
              {user.college_degree_type} {user.college} (Class of{" "}
              {user.college_grad_year}) - {user.college_major}
            </Typography>
            <Typography variant="body2">{formatLocation()}</Typography>
          </Box>
          <Box width="50%">
            {user.employments.filter((employment) => !employment.end).length ===
            1 ? (
              <Typography variant="body1" fontSize="1.25rem" mt="0.4rem">
                Current Role
              </Typography>
            ) : user.employments.filter((employment) => !employment.end)
                .length > 1 ? (
              <Typography variant="body1">Current Roles</Typography>
            ) : null}
            {user.employments
              .filter((employment) => !employment.end)
              .map((employment, index) => (
                <Typography key={index} variant="body2">
                  {employment.position_title} at {employment.company} since{" "}
                  {employment.start}
                </Typography>
              ))}
          </Box>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <Typography
          variant="h5"
          sx={{ marginLeft: "1.5rem", marginTop: "1rem" }}
        >
          {user.first_name} {user.last_name}
        </Typography>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "primary.main",
            }}
          >
            <Typography variant="body1">
              <strong>College:</strong> {user.college_degree_type}{" "}
              {user.college} (Class of {user.college_grad_year})
            </Typography>
            <Typography variant="body1" sx={{ marginTop: "0.5rem" }}>
              <strong>Major:</strong> {user.college_major}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: "0.5rem" }}>
              <strong>Location:</strong> {formatLocation()}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: "0.5rem" }}>
              <strong>LinkedIn:</strong>
              <Button
                variant="contained"
                color="primary"
                disabled={!profileButtonEnabled}
                onClick={() => {
                  setProfileButtonEnabled(false);
                  report_action("profile_clicked", user.id, {
                    profile_link: user.profile_link,
                  }).then(() => {
                    window.open(user.profile_link, "_blank");
                  });
                  setProfileButtonEnabled(true);
                }}
                sx={{ marginLeft: "0.5rem" }}
              >
                Link
              </Button>
            </Typography>
          </DialogContentText>
          {user.further_education.length > 0 && (
            <Box sx={{ marginTop: "2rem" }}>
              <Typography variant="h6">Further Education:</Typography>
              {user.further_education.map((education, index) => (
                <Typography key={index} variant="body1">
                  {education.degree_type} in {education.further_focus} from{" "}
                  {education.school}
                  {education.end_year ? ` (${education.end_year})` : null}
                </Typography>
              ))}
            </Box>
          )}
          <Box sx={{ marginTop: "2rem" }}>
            <Typography variant="h6">Work Experience:</Typography>
            {user.employments
              .slice()
              .sort((a: EmploymentSearchResult, b: EmploymentSearchResult) => {
                if (!a.end && !b.end) {
                  // If both have no end date, compare by start date
                  return (
                    new Date(`${a.start}-01`).getDate() -
                    new Date(`${b.start}-01`).getDate()
                  );
                }
                if (!a.end) return -1;
                if (!b.end) return 1;
                return (
                  new Date(`${a.end}-01`).getDate() -
                  new Date(`${b.end}-01`).getDate()
                );
              })
              .map((employment, index) => (
                <Typography key={index} variant="body1">
                  {employment.position_title} at {employment.company} (
                  {employment.start} -{" "}
                  {employment.end ? employment.end : "Present"})
                </Typography>
              ))}
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            <>
              <CopyToClipboard text={user.email || ""} onCopy={handleCopy}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!user.email}
                  component="a"
                >
                  <Typography variant="button" sx={{ color: "white" }}>
                    Contact Direct
                  </Typography>
                </Button>
              </CopyToClipboard>

              <Snackbar
                open={snackOpen}
                autoHideDuration={3000}
                onClose={() => snackSetOpen(false)}
              >
                <Alert
                  onClose={() => snackSetOpen(false)}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Email address copied to clipboard
                </Alert>
              </Snackbar>
            </>
            <Button
              variant="contained"
              color="primary"
              disabled={!user.is_contactable}
              onClick={() => {
                setWriteMessage(true);
                setSendButtonEnabled(true);
              }}
              sx={{ marginLeft: "1rem" }}
            >
              Contact Through Sefer Echad
            </Button>
          </Box>
          {writeMessage && (
            <>
              <TextField
                label="Message"
                multiline
                minRows={5}
                variant="outlined"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{ marginTop: "1rem" }}
              />
              <Button
                onClick={() => {
                  setSendButtonEnabled(false);
                  handleSendMessage().then(() => setWriteMessage(false));
                }}
                color="primary"
                variant="contained"
                disabled={!sendButtonEnabled || !message}
                sx={{ marginTop: "1rem" }}
              >
                Send
              </Button>
            </>
          )}
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserSearchCard;
