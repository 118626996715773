import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Employment {
  id: number;
  company: string;
  position_title: string;
  start: string;
  end: string | null;
}

interface EditEmploymentDialogProps {
  open: boolean;
  onClose: () => void;
  employment: Employment;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmploymentEditSave: () => void;
  errorMessage: string | null;
  handleEmploymentDelete: (id: number) => void;
}

const formatDate = (dateString: string | null) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${year}-${month}`;
};

const EditEmploymentDialog: React.FC<EditEmploymentDialogProps> = ({
  open,
  onClose,
  employment,
  onInputChange,
  handleEmploymentEditSave,
  errorMessage,
  handleEmploymentDelete,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={1}
      >
        <DialogTitle>Edit Employment</DialogTitle>
        <IconButton
          onClick={() => {
            handleEmploymentDelete(employment.id);
            onClose();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <TextField
          margin="dense"
          name="company"
          label="Company"
          fullWidth
          required
          value={employment.company}
          onChange={onInputChange}
        />
        <TextField
          margin="dense"
          name="position_title"
          label="Position Title"
          fullWidth
          required
          value={employment.position_title}
          onChange={onInputChange}
        />
        <TextField
          margin="dense"
          name="start"
          label="Start Date"
          type="month"
          fullWidth
          required
          value={formatDate(employment.start)}
          onChange={onInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          name="end"
          label="End Date"
          type="month"
          fullWidth
          value={formatDate(employment.end)}
          onChange={onInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleEmploymentEditSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEmploymentDialog;
