import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import NavBar from "../../components/user-navbar";
import { ReferedUser, PendingInvite } from "../../utils/types";
import ReferredUserCard from "../../components/admin/referred-user-card";

const AdminViewReferralsPage = () => {
  const [referredUsers, setReferredUsers] = useState<ReferedUser[]>([]);
  const [pendingInvites, setPendingInvites] = useState<PendingInvite[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteName, setInviteName] = useState("");
  const [name_options, setNameOptions] = useState<string[]>([]);
  const [initialEmail, setInitialEmail] = useState("");

  useEffect(() => {
    fetchReferredUsers();
    fetchPendingInvites();
  }, []);

  const fetchReferredUsers = async () => {
    try {
      const response = await fetch(buildAPIPath(APIRoutePaths.GetReferrals), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setReferredUsers(data);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error fetching the referred users. Please try again.",
      );
    }
  };

  const fetchPendingInvites = async () => {
    try {
      const response = await fetch(
        buildAPIPath(APIRoutePaths.GetPendingInvites),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        setPendingInvites(data);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error fetching the pending invites. Please try again.",
      );
    }
  };

  const handleInviteSend = async () => {
    try {
      const response = await fetch(buildAPIPath(APIRoutePaths.CreateInvite), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          email: inviteEmail,
          name: inviteName,
          initial_email: initialEmail,
        }),
      });

      if (response.ok) {
        setDialogOpen(false);
        fetchPendingInvites();
        fetchReferredUsers();
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error sending the invite. Please try again.",
      );
    }
  };

  const deleteInvite = async (email: string) => {
    try {
      const response = await fetch(buildAPIPath(APIRoutePaths.DeleteInvite), {
        method: "POST", // Corrected method to POST
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        fetchPendingInvites();
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error canceling the invite. Please try again.",
      );
    }
  };

  return (
    <Box width="100%">
      <NavBar />
      <Box sx={{ padding: "2rem" }}>
        <Typography variant="h4">Referred Users</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          {referredUsers.map((user, index) => (
            <ReferredUserCard
              key={`${index}-referred-user`}
              user={user}
              onInviteClick={() => {
                setInviteEmail(user.email);
                setInviteName(user.referred_names[0]);
                setInitialEmail(user.email);
                setNameOptions(user.referred_names);
                setDialogOpen(true);
              }}
            />
          ))}
        </Box>
        <Box sx={{ display: "flex", gap: "1rem", marginTop: "2rem" }}>
          <Typography variant="h4">Pending Invites</Typography>
          <IconButton
            onClick={() => {
              setInviteEmail("");
              setInviteName("");
              setInitialEmail("");
              setNameOptions([]);
              setDialogOpen(true);
            }}
          >
            <MailIcon /> Create Invite
          </IconButton>
        </Box>
        <Box sx={{ marginTop: "1rem" }}>
          {pendingInvites.map((invite, index) => (
            <Box
              key={`${index}-pending-invite`}
              sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
            >
              <IconButton
                key={`${index}-delete-invite`}
                onClick={() => deleteInvite(invite.email)}
              >
                <DeleteForeverIcon />
              </IconButton>
              <Typography key={`${index}-invite-data`}>
                {invite.email} - {new Date(invite.created_at).toLocaleString()}.
                Refs: {invite.num_referrals}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Send Invite</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            fullWidth
            sx={{ marginTop: "1rem" }}
          />
          <Autocomplete
            freeSolo
            options={name_options}
            value={inviteName}
            onChange={(event, value) => {
              setInviteName(value ?? "");
            }}
            onInputChange={(event, value) => {
              setInviteName(value);
            }}
            fullWidth
            sx={{ marginTop: "1rem" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Name"
                variant="outlined"
                name="Name"
                required
                sx={{ mb: 2 }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleInviteSend}>Send</Button>
        </DialogActions>
      </Dialog>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </Box>
  );
};

export default AdminViewReferralsPage;
