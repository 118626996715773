const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000" // Local development URL
    : "https://sefer-echad-api.azurewebsites.net";

export enum APIRoutePaths {
  // Auth
  LoginForToken = `/api/auth/token`,
  ValidateToken = "/api/auth/validate",
  ValidateAdminToken = "/api/auth/validate-admin",
  ValidateInviteToken = "/api/auth/validate-invite",
  RefreshToken = "/api/auth/refresh",
  Logout = "/api/auth/logout",
  // User
  GetUserData = "/api/user/user-data",
  UpdateUserData = "/api/user/update-user",
  UpdateEmployment = "/api/user/update-employment",
  DeleteEmployment = "/api/user/employment/:employmentId",
  CreateEmployment = "/api/user/create-employment",
  CreateFurtherEducation = "/api/user/create-further-education",
  UpdateFurtherEducation = "/api/user/update-further-education",
  DeleteFurtherEducation = "/api/user/further-education/:furtherEducationId",
  ReferUser = "/api/user/refer",
  GetUserReferrals = "/api/user/referrals",
  DeleteReferral = "/api/user/cancel-referral/:referred_user_email",
  CreateAccount = "/api/user/create-account",
  UpdateInviteEmail = "/api/user/update-invite-email",
  RequestPasswordReset = "/api/user/request-password-reset",
  ResetPassword = "/api/user/reset-password",
  ReportAction = "/api/user/report-action",
  GetInvitedUserEmail = "/api/user/invited-user-email",
  EmailUnsubscribePreferences = "/api/user/email-preferences-unsubscribe-link",
  EmailPreferences = "/api/user/email-preferences",
  // Admin
  GetReferrals = "/api/admin/referrals/all",
  GetPendingInvites = "/api/admin/invites/pending",
  CreateInvite = "/api/admin/create-invite",
  DeleteInvite = "/api/admin/delete-invite",
  GetUsers = "/api/admin/users/all",
  AdminUpdateUser = "/api/admin/update-user",
  // Directory
  SearchUsers = "/api/directory/search",
  SendMessage = "/api/directory/send-message",
}

export const buildAPIPath = (
  path: string,
  params?: Record<string, string | number>,
) => {
  let url = path;
  if (params) {
    for (const key in params) {
      url = url.replace(`:${key}`, params[key].toString());
    }
  }
  return `${API_BASE_URL}${url}`;
};

export const report_action = async (
  action: string,
  target_user_id: number,
  data?: Record<string, any>,
) => {
  try {
    await fetch(buildAPIPath(APIRoutePaths.ReportAction), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        action,
        target_user_id,
        data,
      }),
    });
  } catch (error) {
    console.error("Error:", error);
  }
};
