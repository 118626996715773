import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import RoutePaths from "../../routes/router-config";
import logo from "../../assets/images/logo.svg";
import { EmailPreferences } from "../../utils/types";

const EmailUnsubscribePage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [errorMessage, setErrorMessage] = useState("");
  const [emailPreferences, setEmailPreferences] = useState<EmailPreferences>({
    board_communications: true,
    community_updates: true,
  });

  if (!token) {
    window.location.href = RoutePaths.Index;
  }

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await fetch(
          buildAPIPath(APIRoutePaths.EmailUnsubscribePreferences),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.ok) {
          const data = await response.json();
          setEmailPreferences(data); // Set the email preferences
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.detail);
        }
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage(
          "There was an error processing your request. Please try again.",
        );
      }
    };
    fetchPreferences();
  }, [token]);

  const handleUpdatePreferences = async () => {
    try {
      const response = await fetch(
        buildAPIPath(APIRoutePaths.EmailUnsubscribePreferences),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(emailPreferences),
        },
      );
      if (response.ok) {
        window.location.href = RoutePaths.Index;
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error updating your email preferences. Please try again.",
      );
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailPreferences({
      ...emailPreferences,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "white",
        textAlign: "center",
        wordWrap: "break-word",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          mb: 4, // Margin bottom to separate the logo from the form
        }}
      >
        <img
          src={logo}
          alt="login"
          style={{ width: "300px", height: "auto" }}
        />
      </Box>
      {/* Email Preferences Form */}
      <Box
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          handleUpdatePreferences();
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "400px", // Limit the form width
          padding: "2rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: add some shadow to the form
        }}
      >
        <Typography variant="h5" gutterBottom>
          Email Preferences
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={emailPreferences.board_communications}
              onChange={handleSwitchChange}
              name="board_communications"
            />
          }
          label="Board Communications"
        />
        <FormControlLabel
          control={
            <Switch
              checked={emailPreferences.community_updates}
              onChange={handleSwitchChange}
              name="community_updates"
            />
          }
          label="Community  Announcements"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Update Preferences
        </Button>
        {errorMessage && (
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default EmailUnsubscribePage;
