import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Replace with your primary color
    },
    secondary: {
      main: "#ffffff", // Replace with your secondary color
    },
  },
  typography: {
    h1: {
      fontFamily: "Playfair Display, serif", // Replace with your preferred font family
      fontSize: "4rem", // Set the default font size for h1
    },
    h2: {
      fontFamily: "Playfair Display, serif", // Replace with your preferred font family
      fontSize: "3.5rem", // Set the default font size for h2
    },
    h3: {
      fontFamily: "Playfair Display, serif", // Replace with your preferred font family
      fontSize: "3rem", // Set the default font size for h3
    },
    h4: {
      fontFamily: "Playfair Display, serif", // Replace with your preferred font family
      fontSize: "2.5rem", // Set the default font size for h4
    },
    h5: {
      fontFamily: "Playfair Display, serif", // Replace with your preferred font family
      fontSize: "2rem", // Set the default font size for h5
    },
    h6: {
      fontFamily: "Playfair Display, serif", // Replace with your preferred font family
      fontSize: "1.5rem", // Set the default font size for h6
    },
    // You can also set default styles for other text elements if needed
    body1: {
      fontFamily: "Open Sans, sans-serif", // Replace with your preferred font family
      fontSize: "1rem", // Set the default font size for body1
    },
    body2: {
      fontFamily: "Open Sans, sans-serif", // Replace with your preferred font family
      fontSize: "0.875rem", // Set the default font size for body2
    },
    button: {
      fontFamily: "Open Sans, sans-serif", // Replace with your preferred font family
      fontSize: "0.875rem", // Set the default font size for body1
    },
  },
});

export default theme;
