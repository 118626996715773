import React from "react";

import MyAccountPage from "../pages/user/my-account-page";
import ErrorPage from "../pages/error-page";
import IndexPage from "../pages/user/index-page";
import ProtectedRoute, { ProtectCreateUserRoute } from "./protected-route";
import RoutePaths from "./router-config";
import InvitedUserPage from "../pages/user/invited-user-page";
import UserSearchPage from "../pages/directory/search-users-page";
import RequestPasswordResetPage from "../pages/user/request-reset-password-page";
import ResetPasswordPage from "../pages/user/reset-password-page";
import ReferralPage from "../pages/user/referral-page";
import EmailUnsubscribePage from "../pages/user/email-unsubscribe-page";

const userRoutes = [
  {
    path: RoutePaths.Index,
    element: <IndexPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePaths.SearchUsers,
    element: (
      <ProtectedRoute>
        <UserSearchPage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePaths.MyAccount,
    element: (
      <ProtectedRoute>
        <MyAccountPage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePaths.Referrals,
    element: (
      <ProtectedRoute>
        <ReferralPage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePaths.CreateAccount,
    element: (
      <ProtectCreateUserRoute>
        <InvitedUserPage />
      </ProtectCreateUserRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePaths.RequestPasswordReset,
    element: <RequestPasswordResetPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePaths.ResetPassword,
    element: <ResetPasswordPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePaths.EmailUnsubscribe,
    element: <EmailUnsubscribePage />,
    errorElement: <ErrorPage />,
  },
];

export default userRoutes;
