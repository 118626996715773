import React, { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import RoutePaths from "../../routes/router-config";
import logo from "../../assets/images/logo.svg";

const handleRequestResetPassword = async (
  event: React.FormEvent<HTMLFormElement>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  const email = formData.get("email") as string;
  try {
    const response = await fetch(
      buildAPIPath(APIRoutePaths.RequestPasswordReset),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      },
    );

    if (response.ok) {
      // Handle successful request
      window.location.href = RoutePaths.Index;
    } else {
      const errorData = await response.json();
      console.log("Error:", errorData);
      setErrorMessage(errorData.detail);
    }
  } catch (error) {
    console.error("Error:", error);
    setErrorMessage("There was an error sending reset link. Please try again.");
  }
};

const RequestResetPasswordPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "white",
        textAlign: "center",
        wordWrap: "break-word",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          mb: 4, // Margin bottom to separate the logo from the form
        }}
      >
        <img
          src={logo}
          alt="login"
          style={{ width: "300px", height: "auto" }}
        />
      </Box>
      {/* Reset Password Form */}
      <Box
        component="form"
        onSubmit={(event) => handleRequestResetPassword(event, setErrorMessage)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "400px", // Limit the form width
          padding: "2rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: add some shadow to the form
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          required
          fullWidth
          sx={{ mb: 2 }} // Margin bottom to space out form fields
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Reset Password
        </Button>
        {errorMessage && (
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default RequestResetPasswordPage;
