import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
// import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import NavBar from "../../components/user-navbar";
// import { BulletinMessage } from "../../utils/types";

const BulletinPage: React.FC = () => {
  return (
    <Box width="100%">
      <NavBar />
      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="center"
        sx={{ mt: 4 }}
      >
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              textAlign: "center",
              alignItems: "center",
              wordWrap: "break-word",
              padding: 2,
            }}
          >
            <Box width="40%">
              <Typography variant="h4">Community Bulletin</Typography>
              <Typography
                variant="body1"
                sx={{ mt: 4, mb: 4, fontFamily: "Playfair Display, serif" }}
              >
                We are introducing a new feature for community messages and
                announcements.
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 2, mb: 4, fontFamily: "Playfair Display, serif" }}
              >
                If you have an announcement you would like to share with the
                community, please email us at{" "}
                <Link href="mailto:board@seferechad.com">
                  board@seferechad.com
                </Link>
                , and we will be happy to post it for you.
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/*
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              textAlign: "center",
              alignItems: "center",
              wordWrap: "break-word",
              padding: 2,
            }}
          >
            <Box
              sx={{
                width: "100%", // Make sure this is 100% to take full width
                mx: "auto", // Center the form container
              }}
            >
              {referrals && referrals.length > 0 ? (
                referrals.map((referral: Referral) => (
                  <Box
                    key={referral.email}
                    sx={{
                      padding: 2,
                      boxShadow: "0px 0px 5px 0px #ccc",
                      height: "125px",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Typography variant="body1">{referral.name}</Typography>
                    <Typography variant="body2">{referral.email}</Typography>
                    <Button
                      onClick={() =>
                        deleteUserReferral(referral.email).then(() =>
                          getUserReferrals().then((data) => {
                            setReferrals(data);
                          }),
                        )
                      }
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography variant="body2">No referrals yet.</Typography>
              )}
            </Box>
          </Box>
        </Grid>
        */}
      </Grid>
    </Box>
  );
};

export default BulletinPage;
