import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Switch,
  FormControlLabel,
  Autocomplete,
  Pagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import NavBar from "../../components/user-navbar";
import { UserAdmin } from "../../utils/types";
import {
  countries,
  states,
  colleges,
  undergrad_degrees,
} from "../../utils/static_data";
import DatePicker, { DatePickerProps } from "@mui/lab/DatePicker";

const AdminViewUsersPage = () => {
  const [users, setUsers] = useState<UserAdmin[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<Partial<UserAdmin>>({});
  const [page, setPage] = useState(1);
  const pageSize = 100;
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${buildAPIPath(APIRoutePaths.GetUsers)}?page=${page}&pageSize=${pageSize}&searchQuery=${searchQuery}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data.users);
        setTotalUsers(data.total);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error fetching the users. Please try again.",
      );
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on mount

  const handleUserEdit = (user: UserAdmin) => {
    setEditingUser(user);
    setDialogOpen(true);
  };

  const handleFieldChange = (field: keyof UserAdmin, value: any) => {
    setEditingUser({ ...editingUser, [field]: value });
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        buildAPIPath(APIRoutePaths.AdminUpdateUser),
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(editingUser),
        },
      );

      if (response.ok) {
        setDialogOpen(false);
        fetchUsers();
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error updating the user. Please try again.",
      );
    }
  };

  const handleSearchClick = () => {
    setPage(1); // Reset to the first page on new search
    fetchUsers();
  };

  return (
    <Box width="100%">
      <NavBar />
      <Box sx={{ padding: "2rem" }}>
        <Typography variant="h4">All Users</Typography>
        <Box display="flex" alignItems="center" marginBottom="1rem">
          <TextField
            label="Search Users"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchClick}
            sx={{ marginLeft: "1rem" }}
          >
            Search
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          {users &&
            users.map((user, index) => (
              <Box
                key={index}
                sx={{
                  border: "1px solid #ccc",
                  padding: "1rem",
                  borderRadius: "8px",
                }}
              >
                <Typography>
                  {user.first_name} {user.last_name}
                </Typography>
                <Typography>{user.email}</Typography>
                <Typography>
                  {user.is_active ? "Account Active" : "Account Inactive"}
                </Typography>
                {user.is_admin ? <Typography> Admin User</Typography> : null}
                <IconButton onClick={() => handleUserEdit(user)}>
                  <EditIcon /> Edit
                </IconButton>
              </Box>
            ))}
        </Box>
        <Pagination
          count={Math.ceil(totalUsers / pageSize)}
          page={page}
          onChange={(event, value) => setPage(value)}
          sx={{ marginTop: "1rem" }}
        />
      </Box>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="First Name"
            value={editingUser.first_name || ""}
            onChange={(e) => handleFieldChange("first_name", e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Last Name"
            value={editingUser.last_name || ""}
            onChange={(e) => handleFieldChange("last_name", e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Email"
            value={editingUser.email || ""}
            onChange={(e) => handleFieldChange("email", e.target.value)}
            fullWidth
          />
          <Autocomplete
            options={["M", "F", "O"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sex"
                variant="outlined"
                name="sex"
                margin="dense"
              />
            )}
            value={editingUser.sex || ""}
            onChange={(event, value) => handleFieldChange("sex", value || "")}
            fullWidth
          />
          <DatePicker
            label="Birthdate"
            value={
              editingUser.birthdate ? new Date(editingUser.birthdate) : null
            }
            onChange={(newValue: Date) =>
              handleFieldChange("birthdate", newValue)
            }
            renderInput={(params: DatePickerProps<Date>) => (
              <TextField {...params} margin="dense" fullWidth />
            )}
          />
          <TextField
            margin="dense"
            label="City"
            value={editingUser.city || null}
            onChange={(e) => handleFieldChange("city", e.target.value || null)}
            fullWidth
          />
          <Autocomplete
            options={states}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                variant="outlined"
                name="state"
                margin="dense"
              />
            )}
            value={editingUser.state || ""}
            onChange={(event, value) =>
              handleFieldChange("state", value || null)
            }
            fullWidth
          />
          <Autocomplete
            options={countries}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                name="country"
                margin="dense"
              />
            )}
            value={editingUser.country || ""}
            onChange={(event, value) =>
              handleFieldChange("country", value || "")
            }
            fullWidth
          />
          <Autocomplete
            options={[...colleges, "Other"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="College"
                variant="outlined"
                name="college"
                margin="dense"
              />
            )}
            value={editingUser.college || ""}
            onChange={(event, value) =>
              handleFieldChange("college", value || "")
            }
            fullWidth
          />
          {editingUser.college === "Other" && (
            <TextField
              label="Other College"
              variant="outlined"
              type="text"
              name="other_college"
              value={editingUser.college}
              onChange={(e) => handleFieldChange("college", e.target.value)}
              fullWidth
              margin="dense"
            />
          )}
          <Autocomplete
            options={undergrad_degrees}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Degree Type"
                variant="outlined"
                name="degree_type"
                margin="dense"
              />
            )}
            value={
              undergrad_degrees.find(
                (degree) => degree.value === editingUser.college_degree_type,
              ) || null
            }
            onChange={(event, value) =>
              handleFieldChange("college_degree_type", value?.value || "")
            }
            fullWidth
          />
          <TextField
            margin="dense"
            label="Major"
            value={editingUser.college_major || ""}
            onChange={(e) => handleFieldChange("college_major", e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Graduation Year"
            value={editingUser.college_grad_year || ""}
            onChange={(e) =>
              handleFieldChange("college_grad_year", e.target.value)
            }
            fullWidth
          />
          <TextField
            margin="dense"
            label="Profile Link"
            value={editingUser.profile_link || ""}
            onChange={(e) => handleFieldChange("profile_link", e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Send Contacts per Day"
            value={editingUser.send_contacts_per_day || ""}
            type="number"
            onChange={(e) =>
              handleFieldChange("send_contacts_per_day", e.target.value)
            }
            fullWidth
          />
          <TextField
            margin="dense"
            label="Receive Contacts per Day"
            value={editingUser.receive_contacts_per_day || ""}
            type="number"
            onChange={(e) =>
              handleFieldChange("receive_contacts_per_day", e.target.value)
            }
            fullWidth
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(editingUser.is_admin)}
                onChange={(e) =>
                  handleFieldChange("is_admin", e.target.checked)
                }
                color="primary"
              />
            }
            label="Is Admin"
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(editingUser.is_active)}
                onChange={(e) =>
                  handleFieldChange("is_active", e.target.checked)
                }
                color="primary"
              />
            }
            label="Is Active"
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(editingUser.email_visible)}
                onChange={(e) =>
                  handleFieldChange("email_visible", e.target.checked)
                }
                color="primary"
              />
            }
            label="Email Visibility"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave}>
            <SaveIcon /> Save
          </Button>
        </DialogActions>
      </Dialog>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </Box>
  );
};

export default AdminViewUsersPage;
