export const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
export const countries = [
  "USA",
  "Canada",
  "Mexico",
  "United Kingdom",
  "Israel",
  "Germany",
  "France",
  "Italy",
  "Spain",
  "Australia",
  "Japan",
  "China",
];
export const colleges = [
  "University of Alabama",
  "Alabama Agricultural and Mechanical University",
  "Alabama State University",
  "Auburn University",
  "Tuskegee University",
  "University of Alaska",
  "University of Arizona",
  "Arizona State University",
  "Northern Arizona University",
  "University of Arkansas",
  "Arkansas State University",
  "Art Center College of Design",
  "University of California",
  "California Institute of the Arts",
  "California Institute of Technology",
  "California State University",
  "Claremont Colleges",
  "Mills College",
  "Occidental College",
  "University of the Pacific",
  "University of San Francisco",
  "Santa Clara University",
  "University of Southern California",
  "Stanford University",
  "University of Colorado",
  "Colorado College",
  "Colorado School of Mines",
  "Colorado State University",
  "University of Denver",
  "University of Northern Colorado",
  "Western State Colorado University",
  "University of Bridgeport",
  "Central Connecticut State University",
  "University of Connecticut",
  "Connecticut College",
  "Fairfield University",
  "University of Hartford",
  "Southern Connecticut State University",
  "Trinity College",
  "Wesleyan University",
  "Yale University",
  "University of Delaware",
  "Delaware State University",
  "University of Central Florida",
  "University of Florida",
  "Florida Agricultural and Mechanical University",
  "Florida Atlantic University",
  "Florida State University",
  "University of Miami",
  "Agnes Scott College",
  "Emory University",
  "Fort Valley State University",
  "University of Georgia",
  "Georgia Institute of Technology",
  "Georgia State University",
  "Morehouse College",
  "Oglethorpe University",
  "Spelman College",
  "University of Hawaii",
  "Boise State University",
  "University of Idaho",
  "Idaho State University",
  "Augustana College",
  "University of Chicago",
  "Chicago State University",
  "DePaul University",
  "Dominican University",
  "Eastern Illinois University",
  "University of Illinois",
  "Illinois Institute of Technology",
  "Illinois State University",
  "Knox College",
  "Lewis University",
  "Loyola University Chicago",
  "Northern Illinois University",
  "Northwestern University",
  "Roosevelt University",
  "Southern Illinois University",
  "Western Illinois University",
  "Wheaton College",
  "Ball State University",
  "Butler University",
  "DePauw University",
  "Earlham College",
  "University of Evansville",
  "Goshen College",
  "Indiana University",
  "Indiana State University",
  "Manchester University",
  "University of Notre Dame",
  "Purdue University",
  "Valparaiso University",
  "Coe College",
  "Drake University",
  "Grinnell College",
  "University of Iowa",
  "Iowa State University",
  "Loras College",
  "University of Northern Iowa",
  "Emporia State University",
  "Fort Hays State University",
  "University of Kansas",
  "Kansas State University",
  "Wichita State University",
  "Eastern Kentucky University",
  "University of Kentucky",
  "Kentucky State University",
  "University of Louisville",
  "Murray State University",
  "Western Kentucky University",
  "Grambling State University",
  "University of Louisiana at Monroe",
  "Louisiana State University",
  "Southern University",
  "Tulane University",
  "Bates College",
  "Bowdoin College",
  "Colby College",
  "University of Maine",
  "Johns Hopkins University",
  "University of Maryland",
  "Morgan State University",
  "St. John’s College",
  "Amherst College",
  "Babson College",
  "Bentley University",
  "Boston College",
  "Boston University",
  "Brandeis University",
  "Clark University",
  "Emerson College",
  "Harvard University",
  "College of the Holy Cross",
  "University of Massachusetts",
  "Massachusetts Institute of Technology",
  "Mount Holyoke College",
  "Northeastern University",
  "Smith College",
  "Tufts University",
  "Wellesley College",
  "Westfield State University",
  "Wheaton College",
  "Williams College",
  "Albion College",
  "Central Michigan University",
  "Cranbrook Academy of Art",
  "University of Detroit Mercy",
  "Eastern Michigan University",
  "Ferris State University",
  "Grand Valley State University",
  "Hillsdale College",
  "Kalamazoo College",
  "University of Michigan",
  "Michigan State University",
  "Northern Michigan University",
  "Olivet College",
  "Wayne State University",
  "Western Michigan University",
  "Bemidji State University",
  "Carleton College",
  "University of Minnesota",
  "Minnesota State University",
  "Minnesota State University, Mankato",
  "Minnesota State University Moorhead",
  "St. Cloud State University",
  "St. Olaf College",
  "Winona State University",
  "Alcorn State University",
  "University of Mississippi",
  "Mississippi College",
  "Mississippi State University",
  "University of Southern Mississippi",
  "Lincoln University",
  "University of Missouri",
  "Missouri State University",
  "Northwest Missouri State University",
  "Saint Louis University",
  "Southeast Missouri State University",
  "Truman State University",
  "Washington University in St. Louis",
  "University of Montana",
  "Montana State University",
  "Creighton University",
  "University of Nebraska",
  "University of Nevada",
  "Dartmouth College",
  "University of New Hampshire",
  "Drew University",
  "Fairleigh Dickinson University",
  "Kean University",
  "Montclair State University",
  "Princeton University",
  "Rider University",
  "Rowan College",
  "Rutgers, The State University of New Jersey",
  "Saint Peter’s University",
  "Seton Hall University",
  "University of New Mexico",
  "New Mexico State University",
  "Adelphi University",
  "Alfred University",
  "Bank Street College of Education",
  "Bard College",
  "Barnard College",
  "Canisius College",
  "The City University of New York",
  "Colgate University",
  "Columbia University",
  "Cooper Union",
  "Cornell University",
  "Eastman School of Music",
  "Elmira College",
  "Fordham University",
  "Hamilton College",
  "Hofstra University",
  "Ithaca College",
  "Juilliard School",
  "The New School",
  "State University of New York",
  "New York University",
  "Pace University",
  "Pratt Institute",
  "Rensselaer Polytechnic Institute",
  "University of Rochester",
  "Rochester Institute of Technology",
  "Rockefeller University",
  "Sarah Lawrence College",
  "St. John’s University",
  "Syracuse University",
  "Union College",
  "Vassar College",
  "Yeshiva University",
  "Campbell University",
  "Davidson College",
  "Duke University",
  "Guilford College",
  "University of North Carolina",
  "Wake Forest University",
  "University of North Dakota",
  "North Dakota State University",
  "University of Akron",
  "Antioch University",
  "Bowling Green State University",
  "Case Western Reserve University",
  "University of Cincinnati",
  "Cleveland State University",
  "University of Dayton",
  "Denison University",
  "Hiram College",
  "John Carroll University",
  "Kent State University",
  "Kenyon College",
  "Miami University",
  "Muskingum University",
  "Oberlin College",
  "Ohio University",
  "The Ohio State University",
  "University of Toledo",
  "Wilberforce University",
  "Xavier University",
  "Youngstown State University",
  "University of Central Oklahoma",
  "Langston University",
  "Northeastern State University",
  "University of Oklahoma",
  "Oklahoma State University",
  "Oral Roberts University",
  "University of Tulsa",
  "University of Oregon",
  "Oregon Health and Science University",
  "Oregon State University",
  "Portland State University",
  "Reed College",
  "Allegheny College",
  "Bryn Mawr College",
  "Bucknell University",
  "California University of Pennsylvania",
  "Carnegie Mellon University",
  "Clarion University of Pennsylvania",
  "Dickinson College",
  "Drexel University",
  "Duquesne University",
  "Edinboro University of Pennsylvania",
  "Franklin and Marshall College",
  "Gettysburg College",
  "Haverford College",
  "Indiana University of Pennsylvania",
  "Kutztown University of Pennsylvania",
  "La Salle University",
  "Lafayette College",
  "Lehigh University",
  "Lock Haven University of Pennsylvania",
  "Lycoming College",
  "University of Pennsylvania",
  "University of Pittsburgh",
  "Saint Joseph’s University",
  "Slippery Rock University of Pennsylvania",
  "Swarthmore College",
  "Temple University",
  "Thomas Jefferson University",
  "Villanova University",
  "West Chester University of Pennsylvania",
  "Widener University",
  "Brown University",
  "Providence College",
  "University of Rhode Island",
  "Rhode Island School of Design",
  "College of Charleston",
  "The Citadel",
  "Clemson University",
  "Furman University",
  "University of South Carolina",
  "South Carolina State University",
  "University of South Dakota",
  "South Dakota State University",
  "East Tennessee State University",
  "Fisk University",
  "University of Memphis",
  "University of Tennessee",
  "Tennessee State University",
  "Vanderbilt University",
  "Angelo State University",
  "Austin College",
  "Baylor University",
  "University of Houston",
  "Lamar University",
  "University of North Texas",
  "Rice University",
  "Sam Houston State University",
  "Southern Methodist University",
  "Stephen F. Austin State University",
  "University of Texas",
  "Texas A&M University",
  "Texas Christian University",
  "Texas Southern University",
  "Texas State University",
  "Texas Tech University",
  "Texas Woman’s University",
  "Brigham Young University",
  "University of Utah",
  "Utah State University",
  "Weber State University",
  "Bennington College",
  "Castleton State College",
  "Middlebury College",
  "University of Vermont",
  "George Mason University",
  "Hampton University",
  "National Defense University",
  "Old Dominion University",
  "University of Richmond",
  "Roanoke College",
  "University of Virginia",
  "Virginia Commonwealth University",
  "Virginia State University",
  "Virginia Tech",
  "Washington and Lee University",
  "College of William & Mary",
  "Central Washington University",
  "Gonzaga University",
  "Seattle University",
  "University of Washington",
  "Washington State University",
  "Western Washington University",
  "American University",
  "The Catholic University of America",
  "University of the District of Columbia",
  "Gallaudet University",
  "The George Washington University",
  "Georgetown University",
  "Howard University",
  "National Defense University",
  "Marshall University",
  "West Liberty University",
  "West Virginia University",
  "Beloit College",
  "Marquette University",
  "University of Wisconsin",
  "University of Wyoming",
  "University of Cambridge",
  "Imperial College London",
  "University of London",
  "London School of Economics and Political Science",
  "University of Manchester",
  "University of Oxford",
  "University of St. Andrews",
  "Winchester College",
  "University of Edinburgh",
  "University of Glasgow",
  "Acadia University",
  "University of Alberta",
  "Bishop’s University",
  "University of British Columbia",
  "University of Calgary",
  "Dalhousie University",
  "University of Guelph",
  "Laval University",
  "University of Manitoba",
  "McGill University",
  "McMaster University",
  "Memorial University of Newfoundland",
  "University of Montreal",
  "University of New Brunswick",
  "Queen’s University at Kingston",
  "Ryerson University",
  "University of Saskatchewan",
  "Simon Fraser University",
  "University of Toronto",
  "University of Victoria",
  "University of Waterloo",
  "University of Western Ontario",
  "York University",
  "The Hebrew University of Jerusalem",
];

export const undergrad_degrees = [
  { label: "Associate of Arts (A.A.)", value: "AA" },
  { label: "Associate of Science (A.S.)", value: "AS" },
  { label: "Associate of Applied Science (A.A.S.)", value: "AAS" },
  { label: "Associate of General Studies (A.G.S.)", value: "AGS" },
  { label: "Bachelor of Arts (B.A.)", value: "BA" },
  { label: "Bachelor of Science (B.S.)", value: "BS" },
  { label: "Bachelor of Fine Arts (B.F.A.)", value: "BFA" },
];

export const grad_degrees = [
  // Master's Degrees
  { label: "Master of Business Administration (M.B.A.)", value: "MBA" },
  { label: "Master of Fine Arts (M.F.A.)", value: "MFA" },
  { label: "Master of Science (M.S.)", value: "MS" },
  { label: "Master of Arts (M.A.)", value: "MA" },
  { label: "Master of Education (M.Ed.)", value: "MEd" },
  { label: "Master of Public Health (M.P.H.)", value: "MPH" },
  { label: "Master of Social Work (M.S.W.)", value: "MSW" },
  { label: "Master of Engineering (M.Eng.)", value: "MEng" },
  { label: "Master of Laws (L.L.M.)", value: "LLM" },

  // Doctorate Degrees
  { label: "Doctor of Philosophy (Ph.D.)", value: "PhD" },
  { label: "Doctor of Education (Ed.D.)", value: "EdD" },
  { label: "Doctor of Business Administration (D.B.A.)", value: "DBA" },

  // Professional Degrees
  { label: "Juris Doctor (J.D.)", value: "JD" },
  { label: "Doctor of Medicine (M.D.)", value: "MD" },
  { label: "Doctor of Osteopathic Medicine (D.O.)", value: "DO" },
  { label: "Doctor of Dental Surgery (D.D.S.)", value: "DDS" },
  { label: "Doctor of Dental Medicine (D.M.D.)", value: "DMD" },
  { label: "Doctor of Pharmacy (Pharm.D.)", value: "PharmD" },
  { label: "Doctor of Veterinary Medicine (D.V.M.)", value: "DVM" },
];
