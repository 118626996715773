import React from "react";

import ErrorPage from "../pages/error-page";
import RoutePaths from "./router-config";
import ProtectedRoute from "./protected-route";
import BullitenPage from "../pages/community/bulletin-page";

const communityRoutes = [
  {
    path: RoutePaths.Bulletin,
    element: (
      <ProtectedRoute>
        <BullitenPage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
];

export default communityRoutes;
