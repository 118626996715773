import React from "react";
import { Box, Link, Typography } from "@mui/material";
import logo from "../assets/images/logo.svg";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        borderTop: "1px solid #ccc",
        backgroundColor: "primary.main",
        width: "100%",
        margin: 0, // Ensure no margin around the footer
      }}
    >
      <Typography variant="body2" sx={{ color: "white", textAlign: "center" }}>
        <Link href="mailto:support@seferechad.com" sx={{ color: "white" }}>
          support@seferechad.com
        </Link>
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "1rem",
          flexDirection: "row",
        }}
      >
        <img src={logo} alt="Logo" style={{ height: "50px", width: "auto" }} />
        <Typography variant="body2" sx={{ color: "white", marginLeft: "1rem" }}>
          © 2024 SEFER ECHAD
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
