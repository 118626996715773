import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  IconButton,
  Box,
  Autocomplete,
} from "@mui/material";
import { grad_degrees, colleges } from "../../utils/static_data";
import DeleteIcon from "@mui/icons-material/Delete";

interface FurtherEducation {
  id: number;
  school: string;
  degree_type: string;
  further_focus: string;
  end_year: number | null;
}

interface EditFurtherEducationDialogProps {
  open: boolean;
  onClose: () => void;
  furtherEducation: FurtherEducation;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFurtherEducationEditSave: () => void;
  errorMessage: string | null;
  handleFurtherEducationDelete: (id: number) => void;
}

const EditFurtherEducationDialog: React.FC<EditFurtherEducationDialogProps> = ({
  open,
  onClose,
  furtherEducation,
  onInputChange,
  handleFurtherEducationEditSave,
  errorMessage,
  handleFurtherEducationDelete,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={1}
      >
        <DialogTitle>Edit Further Education</DialogTitle>
        <IconButton
          onClick={() => {
            handleFurtherEducationDelete(furtherEducation.id);
            onClose();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Autocomplete
          options={colleges}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="School"
              variant="outlined"
              name="school"
              sx={{ mb: 2 }}
              onChange={onInputChange}
            />
          )}
          value={
            colleges.find((option) => option === furtherEducation.school) ||
            null
          }
        />
        <Autocomplete
          options={grad_degrees}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Degree Type"
              variant="outlined"
              name="degree_type"
              sx={{ mb: 2 }}
              onChange={onInputChange}
            />
          )}
          value={
            grad_degrees.find(
              (option) => option.value === furtherEducation.degree_type,
            ) || null
          }
        />
        <TextField
          margin="dense"
          name="further_focus"
          label="Focus"
          fullWidth
          required
          value={furtherEducation.further_focus}
          onChange={onInputChange}
        />
        <TextField
          margin="dense"
          name="end_year"
          label="End Year"
          type="number"
          fullWidth
          value={furtherEducation.end_year ?? null}
          onChange={onInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleFurtherEducationEditSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFurtherEducationDialog;
