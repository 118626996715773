import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import { EmailPreferences } from "../../utils/types";
import { getAccessToken } from "../../utils/local_store";

interface EmailPreferencesDialogProps {
  open: boolean;
  onClose: () => void;
}

const EmailPreferencesDialog: React.FC<EmailPreferencesDialogProps> = ({
  open,
  onClose,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [emailPreferences, setEmailPreferences] = useState<EmailPreferences>({
    board_communications: true,
    community_updates: true,
  });

  const token = getAccessToken();

  useEffect(() => {
    const fetchPreferences = async () => {
      if (!token) {
        setErrorMessage("User is not authenticated.");
        return;
      }
      try {
        const response = await fetch(
          buildAPIPath(APIRoutePaths.EmailPreferences),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.ok) {
          const data = await response.json();
          setEmailPreferences(data); // Set the email preferences
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.detail);
        }
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage(
          "There was an error processing your request. Please try again.",
        );
      }
    };
    if (open) {
      fetchPreferences();
    }
  }, [open, token]);

  const handleUpdatePreferences = async () => {
    if (!token) {
      setErrorMessage("User is not authenticated.");
      return;
    }
    try {
      const response = await fetch(
        buildAPIPath(APIRoutePaths.EmailPreferences),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(emailPreferences),
        },
      );
      if (response.ok) {
        onClose();
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was an error updating your email preferences. Please try again.",
      );
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailPreferences({
      ...emailPreferences,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Email Preferences</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1rem",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={emailPreferences.board_communications}
              onChange={handleSwitchChange}
              name="board_communications"
            />
          }
          label="Board Communications"
        />
        <FormControlLabel
          control={
            <Switch
              checked={emailPreferences.community_updates}
              onChange={handleSwitchChange}
              name="community_updates"
            />
          }
          label="Community Announcements"
        />
        {errorMessage && (
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleUpdatePreferences}
          variant="contained"
          color="primary"
        >
          Update Preferences
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailPreferencesDialog;
