import React from "react";
import { Box, Typography } from "@mui/material";

interface ErrorPageProps {
  errorMessage?: string;
}

const ErrorPage: React.FC = ({
  errorMessage = "404: There's an unidentified error",
}: ErrorPageProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "white",
        textAlign: "center",
        wordWrap: "break-word",
        overflow: "hidden",
      }}
    >
      <Typography variant="h1" color="black">
        {errorMessage}
      </Typography>
    </Box>
  );
};

export default ErrorPage;
