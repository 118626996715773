import RoutePaths from "./router-config";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  validateUser,
  validateInviteToken,
  validateAdminUser,
} from "../utils/security";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    validateUser().then((result) => {
      setIsAuthenticated(result);
      if (!result) {
        navigate(RoutePaths.Index);
      }
    });
  }, [navigate]);

  if (isAuthenticated === null) {
    return null; // Or a loading spinner/component
  }

  if (isAuthenticated) {
    return <>{children}</>; // Properly render children
  }

  return null;
};

export const ProtectCreateUserRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      validateInviteToken(token).then((result) => {
        setIsAuthenticated(result);
        if (!result) {
          navigate(RoutePaths.Index);
        }
      });
    } else {
      navigate(RoutePaths.Index);
    }
  }, [navigate, token]);

  if (isAuthenticated === null) {
    return null; // Or a loading spinner/component
  }

  if (isAuthenticated) {
    return <>{children}</>; // Properly render children
  }

  return null;
};

export const ProtectAdminRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    validateAdminUser().then((result) => {
      setIsAuthenticated(result);
      if (!result) {
        navigate(RoutePaths.Index);
      }
    });
  }, [navigate]);

  if (isAuthenticated === null) {
    return null; // Or a loading spinner/component
  }

  if (isAuthenticated) {
    return <>{children}</>; // Properly render children
  }

  return null;
};

export default ProtectedRoute;
