import RoutePaths from "../routes/router-config";
import React, { useState, MouseEvent } from "react";
import { Box, Button, Typography, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/images/logo.svg";
import { isUserAdmin } from "../utils/local_store";
import { logout } from "../utils/security";

const NavBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // Open the dropdown
  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        borderBottom: "1px solid #ccc",
        backgroundColor: "white",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          color="primary"
          href={RoutePaths.SearchUsers}
          sx={{ marginLeft: "1rem" }}
        >
          Directory
        </Button>
        <Button
          variant="contained"
          color="primary"
          href={RoutePaths.Bulletin}
          sx={{ marginLeft: "1rem" }}
        >
          Bulletin
        </Button>
        {isUserAdmin() && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: "1rem" }}
              onClick={handleMenuClick}
            >
              <MenuIcon
                sx={{
                  marginRight: "0.5rem",
                }}
              />
              Admin
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ justifyContent: "center" }}
            >
              <MenuItem sx={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  href={RoutePaths.AdminReferrals}
                  sx={{}}
                >
                  Referrals
                </Button>
              </MenuItem>
              <MenuItem sx={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  href={RoutePaths.AdminUsers}
                >
                  Users
                </Button>
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            marginRight: "0.5rem",
            marginLeft: "1rem",
          }}
        >
          Sefer
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{ height: "60px", width: "auto" }}
          />
        </Box>
        <Typography
          variant="h4"
          sx={{
            textAlign: "right",
            marginLeft: "0.5rem",
          }}
        >
          Echad
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          color="primary"
          href={RoutePaths.Referrals}
          sx={{ marginRight: "1rem" }}
        >
          Referrals
        </Button>
        <Button
          variant="contained"
          color="primary"
          href={RoutePaths.MyAccount}
          sx={{ marginRight: "1rem" }}
        >
          My Account
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            logout().then(() => (window.location.href = RoutePaths.Index))
          }
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default NavBar;
