enum RoutePaths {
  // User routes
  MyAccount = "/my-account",
  Referrals = "/my-referrals",
  Index = "/",
  CreateAccount = "/create-account/:token/:replaceEmail",
  RequestPasswordReset = "/request-password-reset",
  ResetPassword = "/reset-password/:token",
  EmailUnsubscribe = "/email-unsubscribe/:token",

  // Admin routes
  AdminReferrals = "/admin-referrals",
  AdminUsers = "/admin-users",

  // Directory routes
  SearchUsers = "/search-users",

  // Community routes
  Bulletin = "/bulletin",
}

export default RoutePaths;
