import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Link,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { ReferedUser } from "../../utils/types";

interface UserCardProps {
  user: ReferedUser;
  onInviteClick: () => void;
}

const ReferredUserCard: React.FC<UserCardProps> = ({ user, onInviteClick }) => {
  return (
    <Card sx={{ width: 400, marginBottom: "1rem" }}>
      <CardContent>
        <Typography variant="h6">{user.email}</Typography>
        <Typography variant="body2">
          Number of Referrals: {user.num_referrals}
        </Typography>
        {user.referred_names.map((referredName, index) => (
          <Box key={index} sx={{ marginTop: "0.5rem", padding: 1 }}>
            <Typography variant="body2">{referredName}</Typography>
            <Typography variant="body2" component="div">
              <Link
                href={user.referred_profile_links[index]}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ wordBreak: "break-all" }}
              >
                {user.referred_profile_links[index]}
              </Link>
            </Typography>
            <Typography variant="body2">
              Referred By: {user.referrer_names[index]},{" "}
              {user.referrer_emails[index]}
            </Typography>
          </Box>
        ))}
        <IconButton onClick={onInviteClick}>
          <MailIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export default ReferredUserCard;
