import React, { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import RoutePaths from "../../routes/router-config";
import logo from "../../assets/images/logo.svg";

const passwordRequirements =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

const handleResetPassword = async (
  event: React.FormEvent<HTMLFormElement>,
  token: string,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
  password: string,
  confirmPassword: string,
) => {
  event.preventDefault();

  if (password !== confirmPassword) {
    setErrorMessage("Passwords do not match.");
    return;
  }

  if (!passwordRequirements.test(password)) {
    setErrorMessage(
      "Password must be at least 10 characters long, contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol.",
    );
    return;
  }

  try {
    const response = await fetch(buildAPIPath(APIRoutePaths.ResetPassword), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password, token }),
    });

    if (response.ok) {
      // Handle successful password reset
      window.location.href = RoutePaths.Index;
    } else {
      const errorData = await response.json();
      console.log("Error:", errorData);
      setErrorMessage(errorData.detail);
    }
  } catch (error) {
    console.error("Error:", error);
    setErrorMessage(
      "There was an error resetting the password. Please try again.",
    );
  }
};

const ResetPasswordPage: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { token } = useParams<{ token: string }>();
  if (!token) {
    window.location.href = RoutePaths.Index;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "white",
        textAlign: "center",
        wordWrap: "break-word",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          mb: 4, // Margin bottom to separate the logo from the form
        }}
      >
        <img
          src={logo}
          alt="login"
          style={{ width: "300px", height: "auto" }}
        />
      </Box>
      {/* New Password Form */}
      <Box
        component="form"
        onSubmit={(event) =>
          handleResetPassword(
            event,
            token ? token : "",
            setErrorMessage,
            password,
            confirmPassword,
          )
        }
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "400px", // Limit the form width
          padding: "2rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: add some shadow to the form
        }}
      >
        <TextField
          label="New Password"
          variant="outlined"
          type="password"
          name="password"
          required
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2 }} // Margin bottom to space out form fields
        />
        <TextField
          label="Confirm Password"
          variant="outlined"
          type="password"
          name="confirmPassword"
          required
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ mb: 2 }} // Margin bottom to space out form fields
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Set New Password
        </Button>
        {errorMessage && (
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;
