import React, { useEffect, useState } from "react";
import { Box, Typography, Button, TextField, Link, Grid } from "@mui/material";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import { getAccessToken } from "../../utils/local_store";
import DeleteIcon from "@mui/icons-material/Delete";
import { Form } from "react-router-dom";
import NavBar from "../../components/user-navbar";
import { Referral } from "../../utils/types";

const getUserReferrals = async () => {
  try {
    const response = await fetch(buildAPIPath(APIRoutePaths.GetUserReferrals), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.json();
  } catch (error) {
    console.error("Error:", error);
  }
};

const referUser = async (
  email: string,
  first_name: string,
  last_name: string,
  profile_url: string,
) => {
  try {
    const response = await fetch(buildAPIPath(APIRoutePaths.ReferUser), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({ email, first_name, last_name, profile_url }),
    });
    if (response.ok) {
      return "";
    } else {
      const errorData = await response.json();
      console.log("Error:", errorData);
      return errorData.detail;
    }
  } catch (error) {
    console.error("Error:", error);
    return "There was an error referring the user. Please try again.";
  }
};

const deleteUserReferral = async (email: string) => {
  try {
    await fetch(
      buildAPIPath(APIRoutePaths.DeleteReferral, {
        referred_user_email: email,
      }),
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
    return true;
  } catch (error) {
    console.error("Error:", error);
  }
};

const ReferralPage: React.FC = () => {
  const [referrals, setReferrals] = useState<Referral[] | null>(null);
  const [referralErrorMessage, setReferralErrorMessage] = useState<
    string | null
  >(null);

  useEffect(() => {
    getUserReferrals().then((data) => {
      setReferrals(data);
    });
  }, []);

  const handleReferral = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateEmail = (email: string): boolean => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const validateUrl = (url: string): boolean => {
      try {
        new URL(url);
        return true;
      } catch (_) {
        return false;
      }
    };

    const formData = new FormData(e.currentTarget);
    const email = (formData.get("email") as string).trim();
    const confirm_email = (formData.get("confirm_email") as string).trim();
    const first_name = (formData.get("first_name") as string).trim();
    const last_name = (formData.get("last_name") as string).trim();
    const profile_url = (formData.get("profile_url") as string).trim();

    if (!email || !confirm_email || !first_name || !last_name || !profile_url) {
      setReferralErrorMessage("All fields are required");
      return;
    } else if (!validateEmail(email)) {
      setReferralErrorMessage("Invalid email");
      return;
    } else if (email !== confirm_email) {
      setReferralErrorMessage("Emails do not match");
      return;
    } else if (!validateUrl(profile_url)) {
      setReferralErrorMessage("Invalid profile URL");
      return;
    }

    const error = await referUser(email, first_name, last_name, profile_url);
    if (error) {
      setReferralErrorMessage(error);
    } else {
      (e.target as HTMLFormElement).reset();
      getUserReferrals().then((data) => {
        setReferrals(data);
      });
    }
  };

  return (
    <Box width="100%">
      <NavBar />
      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="center"
        sx={{ mt: 4 }}
      >
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              textAlign: "center",
              alignItems: "center",
              wordWrap: "break-word",
              padding: 2,
            }}
          >
            <Box width="40%">
              <Typography variant="h4">Referrals</Typography>
              <Typography
                variant="body1"
                sx={{ mt: 4, mb: 4, fontFamily: "Playfair Display, serif" }}
              >
                Sefer Echad is a network for people dedicated to engaging with
                and supporting the Jewish community driven by a commitment to
                the Jewish community and a love for Israel. Sefer Echad is
                maintained by a board of facilitators.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mt: 2,
                  fontWeight: "800",
                  fontSize: "1.25rem",
                  fontFamily: "Playfair Display, serif",
                }}
              >
                Membership Approval
              </Typography>
              <Typography
                variant="body1"
                sx={{ mb: 4, fontFamily: "Playfair Display, serif" }}
              >
                To join Sefer Echad, prospective members must obtain a minimum
                of two referrals from current members and receive approval from
                the board.
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 2, mb: 4, fontFamily: "Playfair Display, serif" }}
              >
                You may create up to ten referrals at a time. Once a referral
                has been reviewed, it no longer counts towards your limit.
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 2, mb: 4, fontFamily: "Playfair Display, serif" }}
              >
                For any inquiries, please contact us at{" "}
                <Link href="mailto:board@seferechad.com">
                  board@seferechad.com
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              textAlign: "center",
              alignItems: "center",
              wordWrap: "break-word",
              padding: 2,
            }}
          >
            <Box
              sx={{
                width: "100%", // Make sure this is 100% to take full width
                mx: "auto", // Center the form container
              }}
            >
              <Typography variant="h6">Your Referrals</Typography>
              {referrals && referrals.length > 0 ? (
                referrals.map((referral: Referral) => (
                  <Box
                    key={referral.email}
                    sx={{
                      padding: 2,
                      boxShadow: "0px 0px 5px 0px #ccc",
                      height: "125px",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Typography variant="body1">{referral.name}</Typography>
                    <Typography variant="body2">{referral.email}</Typography>
                    <Button
                      onClick={() =>
                        deleteUserReferral(referral.email).then(() =>
                          getUserReferrals().then((data) => {
                            setReferrals(data);
                          }),
                        )
                      }
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography variant="body2">No referrals yet.</Typography>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              textAlign: "center",
              alignItems: "center",
              wordWrap: "break-word",
              padding: 2,
              width: "100%", // Ensure the form container takes full width of its parent
            }}
          >
            <Box
              sx={{
                width: "100%", // Make sure this is 100% to take full width
                maxWidth: "50%", // Add a max width to the form container
                mx: "auto", // Center the form container
              }}
            >
              <Form onSubmit={handleReferral}>
                <TextField
                  margin="dense"
                  name="email"
                  label="Email"
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  name="confirm_email"
                  label="Confirm Email"
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  name="first_name"
                  label="First Name"
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  name="last_name"
                  label="Last Name"
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  name="profile_url"
                  label="LinkedIn URL"
                  fullWidth
                  required
                />
                {referralErrorMessage && (
                  <Typography color="error">{referralErrorMessage}</Typography>
                )}
                <Button type="submit" variant="contained" color="primary">
                  Refer
                </Button>
              </Form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReferralPage;
