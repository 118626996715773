const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const getItem = (key: string) => {
  return localStorage.getItem(key);
};

const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

// exported functions
export const setTokens = (access_token: string, refresh_token: string) => {
  setItem("access_token", access_token);
  setItem("refresh_token", refresh_token);
};

export const getAccessToken = () => {
  return getItem("access_token");
};

export const getRefreshToken = () => {
  return getItem("refresh_token");
};

export const removeTokens = () => {
  removeItem("access_token");
  removeItem("refresh_token");
};

export const setUserAdmin = () => {
  setItem("admin", "true");
};

export const removeUserAdmin = () => {
  removeItem("admin");
};

export const isUserAdmin = () => {
  return getItem("admin") === "true";
};
