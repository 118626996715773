import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
  Autocomplete,
  Switch,
  FormControlLabel,
} from "@mui/material";
import DatePicker, { DatePickerProps } from "@mui/lab/DatePicker";
import {
  colleges,
  countries,
  states,
  undergrad_degrees,
} from "../../utils/static_data";
import { UserData } from "../../utils/types";

interface EditUserDialogProps {
  open: boolean;
  onClose: () => void;
  userData: UserData;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAutocompleteChange: (value: string | null, field: string) => void;
  onSave: () => void;
  errorMessage: string | null;
}

const EditUserDialog: React.FC<EditUserDialogProps> = ({
  open,
  onClose,
  userData,
  onInputChange,
  onAutocompleteChange,
  onSave,
  errorMessage,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="first_name"
          label="First Name"
          fullWidth
          value={userData.first_name}
          onChange={onInputChange}
        />
        <TextField
          margin="dense"
          name="last_name"
          label="Last Name"
          fullWidth
          value={userData.last_name}
          onChange={onInputChange}
        />
        <Autocomplete
          options={[...colleges]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="College"
              variant="outlined"
              name="college"
              required
              margin="dense"
            />
          )}
          value={userData.college}
          onChange={(event, value) => onAutocompleteChange(value, "college")}
          fullWidth
          freeSolo
        />
        <Autocomplete
          options={undergrad_degrees}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Degree Type"
              variant="outlined"
              name="degree_type"
              required
              margin="dense"
            />
          )}
          value={
            undergrad_degrees.find(
              (degree: { value: string }) =>
                degree.value === userData.college_degree_type,
            ) || null
          }
          onChange={(event, value) =>
            onAutocompleteChange(value?.value || "", "college_degree_type")
          }
          fullWidth
        />
        <TextField
          margin="dense"
          name="college_major"
          label="Major"
          fullWidth
          required
          value={userData.college_major}
          onChange={onInputChange}
        />
        <TextField
          margin="dense"
          name="college_grad_year"
          label="Graduation Year"
          fullWidth
          required
          value={userData.college_grad_year}
          onChange={onInputChange}
        />
        <DatePicker
          label="Birthdate"
          value={userData.birth_date}
          onChange={(newValue: Date) => {
            if (newValue) {
              onInputChange({
                target: { name: "birth_date", value: newValue },
              } as unknown as React.ChangeEvent<HTMLInputElement>);
            }
          }}
          renderInput={(params: DatePickerProps<Date>) => (
            <TextField {...params} margin="dense" fullWidth />
          )}
        />
        <TextField
          margin="dense"
          name="city"
          label="City"
          fullWidth
          value={userData.city}
          onChange={onInputChange}
        />
        <Autocomplete
          options={[...states]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              variant="outlined"
              name="state"
              required={userData.country === "USA"}
              margin="dense"
            />
          )}
          value={userData.state}
          onChange={(event, value) =>
            onAutocompleteChange(value || null, "state")
          }
          fullWidth
          freeSolo
        />
        <Autocomplete
          options={[...countries]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              variant="outlined"
              name="country"
              required
              margin="dense"
            />
          )}
          value={userData.country}
          onChange={(event, value) =>
            onAutocompleteChange(value || "", "country")
          }
          fullWidth
          freeSolo
        />
        <TextField
          margin="dense"
          name="profile_link"
          label="Profile Link"
          fullWidth
          required
          value={userData.profile_link}
          onChange={onInputChange}
        />
        <FormControlLabel
          sx={{
            marginLeft: 0,
          }}
          control={
            <Switch
              checked={Boolean(userData.email_visible)}
              onChange={onInputChange}
              name="email_visible"
              color="primary"
              sx={{ transform: "scale(1.5)", marginRight: 1 }}
            />
          }
          label="Email Visibility"
        />
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
