import React from "react";

import ErrorPage from "../pages/error-page";
import AdminViewReferralsPage from "../pages/admin/view-referrals-page";
import AdminViewUsersPage from "../pages/admin/view-users-page";
import { ProtectAdminRoute } from "./protected-route";
import RoutePaths from "./router-config";

const adminRoutes = [
  {
    path: RoutePaths.AdminReferrals,
    element: (
      <ProtectAdminRoute>
        <AdminViewReferralsPage />
      </ProtectAdminRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePaths.AdminUsers,
    element: (
      <ProtectAdminRoute>
        <AdminViewUsersPage />
      </ProtectAdminRoute>
    ),
    errorElement: <ErrorPage />,
  },
];

export default adminRoutes;
