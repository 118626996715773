import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import { getAccessToken } from "../../utils/local_store";
import { UserData, Employment, FurtherEducation } from "../../utils/types";
import EditIcon from "@mui/icons-material/Edit";
import { Form } from "react-router-dom";
import NavBar from "../../components/user-navbar";
import EditUserDialog from "../../components/user/edit-user-dialog";
import EditEmploymentDialog from "../../components/user/edit-employment-dialog";
import EditFurtherEducationDialog from "../../components/user/edit-further-education-dialog";
import { colleges, grad_degrees } from "../../utils/static_data";
import MailIcon from "@mui/icons-material/Mail";
import EmailPreferencesDialog from "../../components/user/email-preferences-dialog";

const getUserData = async () => {
  try {
    const response = await fetch(buildAPIPath(APIRoutePaths.GetUserData), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response.json();
  } catch (error) {
    console.error("Error:", error);
  }
};

const updateUser = async (
  user: UserData,
  setUserEditErrorMessage: (errorMessage: string | null) => void,
): Promise<any> => {
  try {
    user.city = user.city || null;
    user.state = user.state || null;

    const response = await fetch(buildAPIPath(APIRoutePaths.UpdateUserData), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(user),
    });
    if (response.ok) {
      setUserEditErrorMessage(null);
      return response.json();
    } else {
      const errorData = await response.json();
      setUserEditErrorMessage(errorData.detail);
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const deleteEmployment = async (employmentId: number) => {
  try {
    const response = await fetch(
      buildAPIPath(APIRoutePaths.DeleteEmployment, { employmentId }),
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
    return response.ok;
  } catch (error) {
    console.error("Error:", error);
    return false;
  }
};

const deleteFurtherEducation = async (furtherEducationId: number) => {
  try {
    const response = await fetch(
      buildAPIPath(APIRoutePaths.DeleteFurtherEducation, {
        furtherEducationId,
      }),
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
    return response.ok;
  } catch (error) {
    console.error("Error:", error);
    return false;
  }
};

const MyAccountPage: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [editEmployment, setEditEmployment] = useState<Employment | null>(null);
  const [userDialogOpen, setUserDialogOpen] = useState<boolean>(false);
  const [employmentDialogOpen, setEmploymentDialogOpen] =
    useState<boolean>(false);
  const [editFurtherEducation, setEditFurtherEducation] =
    useState<FurtherEducation | null>(null);
  const [furtherEducationDialogOpen, setFurtherEducationDialogOpen] =
    useState<boolean>(false);

  const [userEditErrorMessage, setUserEditErrorMessage] = useState<
    string | null
  >(null);
  const [employmentEditErrorMessage, setEmploymentEditErrorMessage] = useState<
    string | null
  >(null);

  const [createEmploymentErrorMessage, setCreateEmploymentErrorMessage] =
    useState<string | null>(null);

  const [furtherEducationErrorMessage, setFurtherEducationErrorMessage] =
    useState<string | null>(null);
  const [
    createFurtherEducationErrorMessage,
    setCreateFurtherEducationErrorMessage,
  ] = useState<string | null>(null);

  const [school, setSchool] = useState<string | null>(null);
  const [degreeType, setDegreeType] = useState<string | null>(null);
  const [emailPreferenceDialogOpen, setEmailPreferenceDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    getUserData().then((data) => {
      if (!data || !data.employments) {
        setUserData(data);
        return;
      }
      const sortedEmployments = data.employments
        .slice()
        .sort((a: Employment, b: Employment) => {
          if (!a.end && !b.end) {
            // If both have no end date, compare by start date
            return (
              new Date(`${a.start}-01`).getDate() -
              new Date(`${b.start}-01`).getDate()
            );
          }
          if (!a.end) return -1;
          if (!b.end) return 1;
          return (
            new Date(`${a.end}-01`).getDate() -
            new Date(`${b.end}-01`).getDate()
          );
        });
      setUserData({
        ...data,
        employments: sortedEmployments,
      });
    });
  }, []);

  const handleEditUser = () => {
    setUserDialogOpen(true);
    setUserEditErrorMessage(null);
  };

  const handleUserDialogClose = () => {
    setUserDialogOpen(false);
  };

  const handleEmploymentEdit = (employment: Employment) => {
    setEditEmployment(employment);
    setEmploymentDialogOpen(true);
    setEmploymentEditErrorMessage(null);
  };

  const handleEmploymentEditSave = async () => {
    if (editEmployment) {
      const response = await fetch(
        buildAPIPath(APIRoutePaths.UpdateEmployment, {
          employmentId: editEmployment.id,
        }),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
          body: JSON.stringify(editEmployment),
        },
      );

      if (response.ok) {
        getUserData().then((data) => {
          setUserData(data);
        });
        setEmploymentDialogOpen(false);
        setEmploymentEditErrorMessage(null);
      } else {
        const errorData = await response.json();
        console.log("Error:", errorData);
        setEmploymentEditErrorMessage(errorData.detail);
      }
    }
  };

  const handleEmploymentDialogClose = () => {
    setEditEmployment(null);
    setEmploymentDialogOpen(false);
  };

  const handleSaveUser = async () => {
    if (userData) {
      const data = await updateUser(userData, setUserEditErrorMessage);
      if (data) {
        setUserData(data);
        setUserDialogOpen(false);
      }
    }
  };

  const handleEmploymentDelete = async (employmentId: number) => {
    const deleted = await deleteEmployment(employmentId);
    if (deleted) {
      if (userData) {
        setUserData({
          ...userData,
          employments: userData.employments
            ? userData.employments.filter(
                (e: Employment) => e.id !== employmentId,
              )
            : undefined,
        });
      }
    } else {
      setUserEditErrorMessage(
        "There was an error deleting the employment record",
      );
    }
  };

  const handleCreateEmployment = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const company = (formData.get("company") as string).trim();
    const position_title = (formData.get("position_title") as string).trim();
    const start = formData.get("start");
    const end = formData.get("end") === "" ? null : formData.get("end");

    if (!company || !position_title || !start) {
      setCreateEmploymentErrorMessage("All fields are required");
      return;
    }

    const response = await fetch(buildAPIPath(APIRoutePaths.CreateEmployment), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({ company, position_title, start, end }),
    });

    if (response.ok) {
      setCreateEmploymentErrorMessage(null);
      (e.target as HTMLFormElement).reset();
      getUserData().then((data) => {
        setUserData(data);
      });
    } else {
      const errorData = await response.json();
      console.log("Error:", errorData);
      setCreateEmploymentErrorMessage(errorData.detail);
    }
  };

  const handleFurtherEducationEdit = (education: FurtherEducation) => {
    setEditFurtherEducation(education);
    setFurtherEducationDialogOpen(true);
    setFurtherEducationErrorMessage(null);
  };

  const handleFurtherEducationEditSave = async () => {
    if (editFurtherEducation) {
      const response = await fetch(
        buildAPIPath(APIRoutePaths.UpdateFurtherEducation, {
          furtherEducationId: editFurtherEducation.id,
        }),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
          body: JSON.stringify(editFurtherEducation),
        },
      );

      if (response.ok) {
        getUserData().then((data) => {
          setUserData(data);
        });
        setFurtherEducationDialogOpen(false);
        setFurtherEducationErrorMessage(null);
      } else {
        const errorData = await response.json();
        console.log("Error:", errorData);
        setFurtherEducationErrorMessage(errorData.detail);
      }
    }
  };

  const handleFurtherEducationDialogClose = () => {
    setEditFurtherEducation(null);
    setFurtherEducationDialogOpen(false);
  };

  const handleFurtherEducationDelete = async (furtherEducationId: number) => {
    const deleted = await deleteFurtherEducation(furtherEducationId);
    if (deleted) {
      if (userData) {
        setUserData({
          ...userData,
          further_education: userData.further_education
            ? userData.further_education.filter(
                (e: FurtherEducation) => e.id !== furtherEducationId,
              )
            : undefined,
        });
      }
    } else {
      setFurtherEducationErrorMessage(
        "There was an error deleting the further education record",
      );
    }
  };

  const handleCreateFurtherEducation = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const school = (formData.get("school") as string).trim();
    const degree_label = (formData.get("degree_type") as string).trim();
    const further_focus = (formData.get("further_focus") as string).trim();
    const end_year = formData.get("end_year")
      ? parseInt(formData.get("end_year") as string)
      : null;

    const degree_type = grad_degrees.find(
      (degree) => degree.label === degree_label,
    )?.value;

    if (!school || !degree_type || !further_focus) {
      setCreateFurtherEducationErrorMessage("All fields are required");
      return;
    }

    const response = await fetch(
      buildAPIPath(APIRoutePaths.CreateFurtherEducation),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({ school, degree_type, further_focus, end_year }),
      },
    );

    if (response.ok) {
      setCreateFurtherEducationErrorMessage(null);
      (e.target as HTMLFormElement).reset();
      getUserData().then((data) => {
        setUserData(data);
      });
      setSchool(null);
      setDegreeType(null);
    } else {
      const errorData = await response.json();
      console.log("Error:", errorData);
      setCreateFurtherEducationErrorMessage(errorData.detail);
    }
  };

  return (
    <Box width="100%">
      <NavBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "white",
          textAlign: "center",
          wordWrap: "break-word",
        }}
      >
        {userData && (
          <>
            <Box
              sx={{ width: "100%", padding: 2, borderBottom: "1px solid #ccc" }}
            >
              <Typography variant="h5">
                {userData.first_name} {userData.last_name}
              </Typography>
              <Typography variant="body1">{userData.email}</Typography>
              <Button
                variant="contained"
                onClick={handleEditUser}
                startIcon={<EditIcon />}
              >
                Edit Profile
              </Button>
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ fontSize: "0.8rem", mt: 2 }}
                  startIcon={
                    <Box display="flex" alignItems="center">
                      <MailIcon sx={{ ml: 1 }} />
                    </Box>
                  }
                  onClick={() => setEmailPreferenceDialogOpen(true)}
                >
                  Email Preferences
                </Button>
                <EmailPreferencesDialog
                  open={emailPreferenceDialogOpen}
                  onClose={() => setEmailPreferenceDialogOpen(false)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                flexGrow: 1,
                justifyContent: "space-between",
                marginBottom: 4,
              }}
            >
              <Box
                sx={{
                  width: "48%",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "60vh",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="h6">Work Experience</Typography>
                  {userData.employments && userData.employments.length ? (
                    userData.employments.map((employment: Employment) => (
                      <Box
                        key={employment.id}
                        sx={{
                          padding: 2,
                          borderBottom: "1px solid #ccc",
                          height: "125px",
                        }}
                      >
                        <Typography variant="body1">
                          {employment.company}
                        </Typography>
                        <Typography variant="body2">
                          {employment.position_title}
                        </Typography>
                        <Typography variant="body2">
                          {employment.start} —{" "}
                          {employment.end ? employment.end : "Present"}
                        </Typography>
                        <Button
                          onClick={() => handleEmploymentEdit(employment)}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2">
                      No experiences added yet.
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                />
                <Box sx={{ alignSelf: "flex-end", mt: 8, ml: 4 }}>
                  <Form onSubmit={handleCreateEmployment}>
                    <TextField
                      margin="dense"
                      name="company"
                      label="Company"
                      fullWidth
                      required
                    />
                    <TextField
                      margin="dense"
                      name="position_title"
                      label="Position Title"
                      fullWidth
                      required
                    />
                    <TextField
                      margin="dense"
                      name="start"
                      label="Start Date"
                      type="month"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      margin="dense"
                      name="end"
                      label="End Date"
                      type="month"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {createEmploymentErrorMessage && (
                      <Typography color="error">
                        {createEmploymentErrorMessage}
                      </Typography>
                    )}
                    <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                      Create
                    </Button>
                  </Form>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "48%",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "60vh",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="h6">Further Education</Typography>
                  {userData.further_education &&
                  userData.further_education.length > 0 ? (
                    userData.further_education.map(
                      (education: FurtherEducation) => (
                        <Box
                          key={education.id}
                          sx={{
                            padding: 2,
                            borderBottom: "1px solid #ccc",
                            height: "125px",
                          }}
                        >
                          <Typography variant="body1">
                            {education.school}
                          </Typography>
                          <Typography variant="body2">
                            {education.degree_type} - {education.further_focus}
                          </Typography>
                          <Typography variant="body2">
                            {education.end_year}
                          </Typography>
                          <Button
                            onClick={() =>
                              handleFurtherEducationEdit(education)
                            }
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                        </Box>
                      ),
                    )
                  ) : (
                    <Typography variant="body2">
                      No further education added yet.
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{ alignSelf: "flex-end", mt: 8, mr: 4, width: "100%" }}
                >
                  <Form onSubmit={handleCreateFurtherEducation}>
                    <Autocomplete
                      options={colleges}
                      value={school}
                      onChange={(event, newValue) => setSchool(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="School"
                          variant="outlined"
                          name="school"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                    <Autocomplete
                      options={grad_degrees}
                      value={
                        grad_degrees.find(
                          (option) => option.value === degreeType,
                        ) || null
                      }
                      onChange={(event, newValue) =>
                        setDegreeType(newValue?.value || null)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Degree Type"
                          variant="outlined"
                          name="degree_type"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                    <TextField
                      margin="dense"
                      name="further_focus"
                      label="Focus"
                      fullWidth
                      required
                    />
                    <TextField
                      margin="dense"
                      name="end_year"
                      label="End Year"
                      type="number"
                      fullWidth
                    />
                    {createFurtherEducationErrorMessage && (
                      <Typography color="error">
                        {createFurtherEducationErrorMessage}
                      </Typography>
                    )}
                    <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                      Add Further Education
                    </Button>
                  </Form>
                </Box>
              </Box>
            </Box>

            <EditUserDialog
              open={userDialogOpen}
              onClose={handleUserDialogClose}
              userData={userData}
              onInputChange={(e) => {
                const { name, value } = e.target;
                if (name === "email_visible") {
                  setUserData({
                    ...userData,
                    email_visible: !userData.email_visible,
                  });
                  return;
                }
                setUserData({ ...userData, [name]: value } as UserData);
              }}
              onAutocompleteChange={(value, field) => {
                if (field === "country" && value !== "USA") {
                  setUserData({
                    ...userData,
                    state: null,
                  });
                }
                setUserData({ ...userData, [field]: value } as UserData);
              }}
              onSave={handleSaveUser}
              errorMessage={userEditErrorMessage}
            />

            <EditEmploymentDialog
              open={employmentDialogOpen}
              onClose={handleEmploymentDialogClose}
              employment={
                editEmployment || {
                  id: 0,
                  company: "",
                  position_title: "",
                  start: "",
                  end: null,
                }
              }
              onInputChange={(e) => {
                const { name, value } = e.target;
                if (editEmployment) {
                  setEditEmployment({
                    ...editEmployment,
                    [name]: value,
                  });
                }
              }}
              handleEmploymentEditSave={handleEmploymentEditSave}
              errorMessage={employmentEditErrorMessage}
              handleEmploymentDelete={handleEmploymentDelete}
            />

            <EditFurtherEducationDialog
              open={furtherEducationDialogOpen}
              onClose={handleFurtherEducationDialogClose}
              furtherEducation={
                editFurtherEducation || {
                  id: 0,
                  school: "",
                  degree_type: "",
                  further_focus: "",
                  end_year: null,
                }
              }
              onInputChange={(e) => {
                const { name, value } = e.target;
                if (editFurtherEducation) {
                  if (name === "end_year") {
                    setEditFurtherEducation({
                      ...editFurtherEducation,
                      [name]: parseInt(value),
                    });
                    return;
                  }
                  setEditFurtherEducation({
                    ...editFurtherEducation,
                    [name]: value,
                  });
                }
              }}
              handleFurtherEducationEditSave={handleFurtherEducationEditSave}
              errorMessage={furtherEducationErrorMessage}
              handleFurtherEducationDelete={handleFurtherEducationDelete}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default MyAccountPage;
