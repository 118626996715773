import { buildAPIPath, APIRoutePaths } from "./api";
import {
  getAccessToken,
  getRefreshToken,
  removeUserAdmin,
  setTokens,
  setUserAdmin,
  removeTokens,
} from "./local_store";

let isRefreshing = false;
let refreshPromise: Promise<boolean> | null = null;

export async function logout(): Promise<void> {
  try {
    const response = await fetch(buildAPIPath(APIRoutePaths.Logout), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getRefreshToken()}`,
      },
    });

    if (response.ok) {
      removeTokens();
    } else {
      throw new Error("Logout failed");
    }
  } catch (error) {
    throw new Error("Logout failed");
  }
}

export async function validateAccessToken(token: string): Promise<boolean> {
  try {
    const response = await fetch(buildAPIPath(APIRoutePaths.ValidateToken), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.ok;
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
}

export async function validateAdminAccessToken(
  token: string,
): Promise<boolean> {
  try {
    const response = await fetch(
      buildAPIPath(APIRoutePaths.ValidateAdminToken),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.ok;
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
}

export async function refreshAccessToken(
  refreshToken: string,
): Promise<boolean> {
  if (isRefreshing) {
    return refreshPromise!;
  }
  isRefreshing = true;

  refreshPromise = new Promise<boolean>((resolve) => {
    (async () => {
      try {
        const response = await fetch(buildAPIPath(APIRoutePaths.RefreshToken), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTokens(data.access_token, data.refresh_token);
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        resolve(false);
      } finally {
        isRefreshing = false;
        refreshPromise = null;
      }
    })();
  });

  return refreshPromise;
}

export async function validateUser(): Promise<boolean> {
  if (isRefreshing) {
    return refreshPromise!;
  }

  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    return false;
  }

  if (accessToken && (await validateAccessToken(accessToken))) {
    return true;
  } else {
    try {
      return await refreshAccessToken(refreshToken);
    } catch (error) {
      return false;
    }
  }
}

export async function validateAdminUser(): Promise<boolean> {
  if (isRefreshing) {
    return refreshPromise!;
  }

  const userValid = await validateUser();
  if (!userValid) {
    return false;
  }

  const accessToken = getAccessToken();
  if (!accessToken) {
    return false;
  }

  if (await validateAdminAccessToken(accessToken)) {
    setUserAdmin();
    return true;
  } else {
    removeUserAdmin();
    return false;
  }
}

export async function validateInviteToken(token: string): Promise<boolean> {
  try {
    const response = await fetch(
      buildAPIPath(APIRoutePaths.ValidateInviteToken),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.ok;
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
}
