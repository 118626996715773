import React, { useState } from "react";
import { Box, Typography, Button, TextField, Link } from "@mui/material";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import { setTokens } from "../../utils/local_store";
import logo from "../../assets/images/logo.svg";
import RoutePaths from "../../routes/router-config";
import { validateAdminUser } from "../../utils/security";

const handleLogin = async (
  event: React.FormEvent<HTMLFormElement>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
  setButtonEnabled: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  const email = formData.get("email") as string;
  const password = formData.get("password") as string;
  try {
    const response = await fetch(buildAPIPath(APIRoutePaths.LoginForToken), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (response.ok) {
      // Redirect to the user's account page and set the access and refresh token
      const data = await response.json();
      console.log("Data:", data);
      setTokens(data.access_token, data.refresh_token);
      validateAdminUser().then(() => {
        window.location.href = RoutePaths.SearchUsers;
      });
    } else {
      const errorData = await response.json();
      console.log("Error:", errorData);
      setErrorMessage(errorData.detail);
      setButtonEnabled(true);
    }
  } catch (error) {
    console.error("Error:", error);
    setErrorMessage("There was an error logging in. Please try again.");
    setButtonEnabled(true);
  }
};

const IndexPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(true);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "white",
        textAlign: "center",
        wordWrap: "break-word",
        width: "100%",
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          mb: 4, // Margin bottom to separate the logo from the form
        }}
      >
        <img
          src={logo}
          alt="login"
          style={{ width: "300px", height: "auto" }}
        />
      </Box>
      {/* Login form */}
      <Box
        component="form"
        onSubmit={(event) => {
          setButtonEnabled(false);
          handleLogin(event, setErrorMessage, setButtonEnabled);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "400px", // Limit the form width
          padding: "2rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: add some shadow to the form
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          required
          fullWidth
          sx={{ mb: 2 }} // Margin bottom to space out form fields
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          name="password"
          required
          fullWidth
          sx={{ mb: 2 }} // Margin bottom to space out form fields
        />
        <Link
          href={RoutePaths.RequestPasswordReset}
          variant="body2"
          sx={{ mb: 2 }}
        >
          Forgot password?
        </Link>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          disabled={!buttonEnabled}
        >
          Login
        </Button>
        {errorMessage && (
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default IndexPage;
