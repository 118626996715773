import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { buildAPIPath, APIRoutePaths } from "../../utils/api";
import NavBar from "../../components/user-navbar";
import { UserSearchResult, PaginatedResponse } from "../../utils/types";
import UserSearchCard from "../../components/user/user-search-card";

const fieldOptions = [
  { label: "Company", value: "company" },
  { label: "Position Title", value: "position_title" },
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "College", value: "college" },
  { label: "Major/Focus", value: "focus" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Country", value: "country" },
];

interface SearchField {
  field: string;
  value: string;
}

const handleSearch = async (
  event: FormEvent<HTMLFormElement>,
  searchFields: SearchField[],
  simpleSearch: string,
  setResults: React.Dispatch<React.SetStateAction<UserSearchResult[]>>,
  setTotal: React.Dispatch<React.SetStateAction<number>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  limit: number,
  offset: number,
): Promise<void> => {
  event.preventDefault();
  const searchParams = {
    simple: simpleSearch || "",
    fields: searchFields.filter((field) => field.field && field.value),
  };
  setErrorMessage("");
  try {
    const response = await fetch(
      buildAPIPath(APIRoutePaths.SearchUsers) +
        `?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(searchParams),
      },
    );

    if (response.ok) {
      const data: PaginatedResponse<UserSearchResult> = await response.json();
      setResults(data.items);
      setTotal(data.total);
    } else {
      const errorData = await response.json();
      setErrorMessage(errorData.detail);
    }
  } catch (error) {
    console.error("Error:", error);
    setErrorMessage(
      "There was an error performing the search. Please try again.",
    );
  }
};

const UserSearchPage: React.FC = () => {
  const [results, setResults] = useState<UserSearchResult[]>([]);
  const [total, setTotal] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchFields, setSearchFields] = useState<SearchField[]>([
    { field: "", value: "" },
  ]);
  const [simpleSearch, setSimpleSearch] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const limit = 100;
  const [offset, setOffset] = useState(0);
  const [searchResultString, setSearchResultString] = useState("");

  const handleFieldChange = (
    index: number,
    key: "field" | "value",
    value: string,
  ) => {
    const newSearchFields = [...searchFields];
    newSearchFields[index][key] = value;
    setSearchFields(newSearchFields);
  };

  const handleAddField = () => {
    setSearchFields([...searchFields, { field: "", value: "" }]);
  };

  const handleRemoveField = (index: number) => {
    const newSearchFields = searchFields.filter((_, i) => i !== index);
    setSearchFields(newSearchFields);
  };

  const handleNextPage = () => {
    setOffset(offset + limit);
    handleSearch(
      new Event("submit") as any,
      searchFields,
      simpleSearch,
      setResults,
      setTotal,
      setErrorMessage,
      limit,
      offset,
    );
  };

  const handlePreviousPage = () => {
    setOffset(Math.max(0, offset - limit));
    handleSearch(
      new Event("submit") as any,
      searchFields,
      simpleSearch,
      setResults,
      setTotal,
      setErrorMessage,
      limit,
      offset,
    );
  };

  useEffect(() => {
    (async () => {
      await handleSearch(
        new Event("submit") as any,
        [{ field: "", value: "" }],
        "",
        setResults,
        setTotal,
        setErrorMessage,
        limit,
        0,
      );
    })();
  }, []); // Empty dependency array to run only once on mount

  return (
    <Box width="100%">
      <NavBar />
      <Box sx={{ padding: "2rem" }}>
        <Box
          component="form"
          onSubmit={(event) => {
            setSearchResultString("Searching...");
            handleSearch(
              event,
              searchFields,
              simpleSearch,
              setResults,
              setTotal,
              setErrorMessage,
              limit,
              offset,
            );
            setSearchResultString("No results found.");
          }}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {!advancedSearch && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <TextField
                  label="Search"
                  value={simpleSearch}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSimpleSearch(e.target.value)
                  }
                  fullWidth
                />
                <Tooltip title="Advanced Search">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSimpleSearch("");
                      setSearchFields([{ field: "name", value: "" }]);
                      setAdvancedSearch(true);
                    }}
                  >
                    <ManageSearchIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
              >
                Search
              </Button>
            </>
          )}
          {advancedSearch && (
            <>
              {searchFields.map((searchField, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel>Field</InputLabel>
                    <Select
                      value={searchField.field}
                      onChange={(e: SelectChangeEvent<string>) =>
                        handleFieldChange(
                          index,
                          "field",
                          e.target.value as string,
                        )
                      }
                      label="Field"
                    >
                      {fieldOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Value"
                    value={searchField.value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFieldChange(index, "value", e.target.value)
                    }
                    fullWidth
                  />

                  {searchFields.length > 1 ? (
                    <IconButton onClick={() => handleRemoveField(index)}>
                      <RemoveIcon />
                    </IconButton>
                  ) : (
                    <Tooltip title="Simple Search">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setSearchFields([{ field: "", value: "" }]);
                          setAdvancedSearch(false);
                        }}
                      >
                        <SearchIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              ))}
              <Button
                onClick={handleAddField}
                variant="contained"
                color="secondary"
              >
                Add Field
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<ManageSearchIcon />}
              >
                Search
              </Button>
            </>
          )}
        </Box>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Box
          sx={{
            marginTop: "2rem",
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          {results && results.length > 0 ? (
            results.map((result, index) => (
              <UserSearchCard
                key={index}
                user={result}
                width="calc(50% - 2rem)"
              />
            ))
          ) : (
            <Typography>{searchResultString}</Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={offset === 0}
            onClick={handlePreviousPage}
          >
            Previous
          </Button>
          <Typography>
            Page {Math.ceil((offset + 1) / limit)} of{" "}
            {Number.isNaN(Math.ceil(total / limit))
              ? 1
              : Math.ceil(total / limit)}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={offset + limit >= total}
            onClick={handleNextPage}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserSearchPage;
