// App.tsx
import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { userRoutes, adminRoutes, communityRoutes } from "./routes/index";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import Footer from "./components/footer";
import logo from "./assets/images/logo.svg";

const App: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const router = createBrowserRouter([
    ...userRoutes,
    ...adminRoutes,
    ...communityRoutes,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowX: "hidden",
          margin: 0, // Ensure no margin around the box
          padding: 0, // Ensure no padding around the box
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              width: "100%",
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="login"
              style={{ width: "300px", height: "auto" }}
            />
            <Typography
              variant="body1"
              align="center"
              fontSize="1.5rem"
              marginTop={8}
            >
              Access to this website is currently unavailable on mobile devices.
              Please use a desktop, laptop, or tablet.
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                flexGrow: 1,
                width: "100%",
                minHeight: "100vh",
              }}
            >
              <RouterProvider router={router} />
            </Box>
            <Footer />
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default App;
